export const OTP_MESSAGE_LENGTH = 6;
export const OTP_MESSAGE_TIMEOUT = 300; // in seconds
export const PRODUCTION = 'production';
export const POST = 'POST';
export const GET = 'GET';
export const PUT = 'PUT';

export * from './paths';
export * from './general';
export * from './regex';
export * from './texts';
export * from './serverStatus';
