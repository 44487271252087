import { personalProfileStore } from '../../store';

import { TABS } from '../../constants';

const Tab = () => {
  const { setTab, tab } = personalProfileStore();

  return (
    <div className='flex flex-wrap mx-auto justify-center gap-6 text-gray-400'>
      <button
        className={tab === TABS.DETAILS ? 'text-buttonBlue underline underline-offset-8 font-bold' : 'text-lightGrey font-medium'}
        type='button'
        onClick={() => setTab(TABS.DETAILS)}
      >
        {TABS.DETAILS}
      </button>
      <button
        className={tab === TABS.DOCUMENTS ? 'text-buttonBlue underline underline-offset-8 font-bold' : 'text-lightGrey font-medium'}
        type='button'
        onClick={() => setTab(TABS.DOCUMENTS)}
      >
        {TABS.DOCUMENTS}
      </button>
    </div>
  );
};

export default Tab;
