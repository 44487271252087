export const CONTACT_US_TITLE = 'יצירת קשר';

export const CUSTOMER_SERVICE = {
  title: 'שירות לקוחות',
  description: 'השירות ניתן בימים א׳-ה׳ בין השעות 16:00 - 08:30',
  phone: {
    whatsapp: 'להמשך פנייה בוואטסאפ',
    phone_number: '*2393',
    phone_mobile_number: '972504424030',
  },
  mail: {
    title: 'אימייל',
    mail_address: 'dotcomisrael@gmail.com',
  },
  request: {
    title: 'שליחת פנייה',
    selectPlaceholder: 'נושא השירות',
    textareaPlaceholder: 'הקלד כאן את תוכן הפנייה...',
    message: 'צפי חזרה עד 3 ימי עסקים',
    send: 'שלח פנייה',
  },
};
export const SERVICE_SELECT_PLACEHOLDER = 'השירות הנבחר';

export const SENT_REQUEST_MESSAGE = 'הפנייה שלך נשלחה.\n'
    + 'נחזור אלייך עד 3 ימי עסקים.';
