import { ReactComponent as Phone } from '../../assets/icons/phone.svg';
import { ReactComponent as Whatsapp } from '../../assets/icons/whatsappLogo.svg';

import { notRegisteredText } from '../../constants';

const ContactUsVia = () => (
  <>
    <div className='bg-gradient-to-b from-[#00C0FF] via-[#4775EA] from-35% via to-[#5558FF] p-[1px] rounded-[9px]'>
      <a
        aria-label='Chat on WhatsApp'
        className='flex flex-col gap-2 p-4 bg-[#e7faff] rounded-lg'
        href={`https://api.whatsapp.com/send?phone=${notRegisteredText.mobile_phone_number}`}
      >
        <Whatsapp className='h-[24px] mx-auto' />
        <p className='font-medium'>{notRegisteredText.whatsapp}</p>
      </a>
    </div>
    <div className='bg-gradient-to-b from-[#00C0FF] via-[#4775EA] from-35% via to-[#5558FF] p-[1px] rounded-[9px]'>
      <a
        aria-label='Phone Call'
        className='flex flex-col gap-2 p-4 bg-[#e7faff] rounded-lg'
        href={`tel:${notRegisteredText.phone_number}`}
      >
        <div className='flex justify-center items-center gap-1'>
          <span className='text-buttonBlue font-bold direction-ltr'>{notRegisteredText.phone_number}</span>
          <Phone className='h-[24px]' />
        </div>
        <p className='font-medium'>{notRegisteredText.phone}</p>
      </a>
    </div>
  </>
);

export default ContactUsVia;
