import { useEffect, useState } from 'react';

import CollapsibleInfoList from '../components/Collapse/CollapsibleInfoList';
import PageLayout from '../components/Layout/PageLayout';

import useApiRequests from '../hooks/useApiRequests';
import { InfoItem } from '../interfaces';

import { FAQ_TITLE, INFO_EMPTY_STATE } from '../constants';

const FaqPage = () => {
  const { getFaq, loading } = useApiRequests();
  const [faq, setFaq] = useState<InfoItem[]>([]);

  useEffect(() => {
    getFaq().then(data => setFaq(data ?? []));
  }, []);

  return (
    <PageLayout header={FAQ_TITLE} loading={loading}>
      <div className='flex flex-col w-10/12 mx-auto mt-5 pb-4 '>
        {faq.length > 0
          ? <CollapsibleInfoList list={faq} />
          : <span className='text-center text-gray-300 text-small'>{INFO_EMPTY_STATE}</span>}
      </div>
    </PageLayout>
  );
};

export default FaqPage;
