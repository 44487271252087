import { useState } from 'react';

import Start from '../components/LoginForm/Start';
import SlideContent from '../components/Walkthrough/SlideContent';
import WalkthroughSlider from '../components/Walkthrough/WalkthroughSlider';

import { WALKTHROUGH_CONTENT } from '../constants';

const WalkthroughPage = () => {
  const [finished, setFinished] = useState(false);
  return (
    <div className='flex flex-col flex-grow bg-gradient-to-b from-[#E9F5FF] via-[#E9F5FF] to-[#FAFAFA]'>
      {finished ? <Start />
        : (
          <WalkthroughSlider
            CarouselCardComponent={SlideContent}
            content={WALKTHROUGH_CONTENT}
            setFinished={setFinished}
          />
        )}
    </div>
  );
};

export default WalkthroughPage;
