import { Link } from 'react-router-dom';

import { uiLogicStore } from '../../store';

import { ReactComponent as HamburgerMenu } from '../../assets/icons/hamburgerMenu.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

const Navbar = () => {
  const { toggleMenuOpen } = uiLogicStore(state => state);

  return (
    <nav className='w-full bg-white mx-auto flex-grow-0 shadow-navbar z-20'>
      <div className='relative flex items-center h-[68px] justify-between mx-[24px]'>
        <div className='flex flex-1 items-center justify-center'>
          <Link className='flex items-center' to='/main'>
            <Logo
              className='h-[33px] w-auto'
            />
          </Link>
        </div>

        <button
          className='absolute inset-y-0 right-0 flex items-center'
          type='button'
          onClick={toggleMenuOpen}
        >
          <HamburgerMenu
            className='ml-[16px] cursor-pointer'
          />
        </button>

      </div>
    </nav>
  );
};
export default Navbar;
