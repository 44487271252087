import { Link } from 'react-router-dom';

import { loginStore } from '../../store/loginStore';

import { loginPageText, MAIN } from '../../constants';

import '../../styles/LoginLetsStart.css';

const Start = () => {
  const { reset } = loginStore();

  return (
    <div className='lets-start-container flex flex-col justify-between'>
      <div className='text-textBlue w-2/4 mt-12 mr-4'>
        <h3 className='font-bold text-2xl'>{loginPageText.start.hi}</h3>
        <p className='font-semibold text-lg'>{loginPageText.start.title}</p>
      </div>
      <div className='rounded-tr-[32px] rounded-tl-[32px] pt-20 px-[34px] pb-16 bg-gradient-to-tr from-cyan-400 via-blue-500 to-blue-700'>
        <Link
          className='flex justify-center py-3.5 rounded-[29px] bg-white text-buttonBlue font-bold flex-end shadow-letsStartButton'
          to={MAIN}
          onClick={() => reset()}
        >
          {loginPageText.start.lets_start_button}
        </Link>
      </div>
    </div>
  );
};

export default Start;
