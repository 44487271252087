import OptionsMenuSection from './OptionsMenuSection';

import { menuSectionOptions } from '../../../utils/MainInfoSection';

import { MENU_OPTION_TITLE } from '../../../constants';

const OptionsMenu = () => (
  <div className='w-full rounded-[21px] flex flex-col bg-white my-[20px]'>
    <div className='font-bold text-small py-[22px] px-[16px]'>
      {MENU_OPTION_TITLE}
    </div>
    {menuSectionOptions.map((option, index) => (<OptionsMenuSection key={`menu-item-${index}`} {...option} />))}
  </div>
);
export default OptionsMenu;
