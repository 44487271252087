import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AdviceCard from '../components/AdviceCard';
import Collapse from '../components/Collapse/Collapse';
import PageLayout from '../components/Layout/PageLayout';
import Details from '../components/PreviousLoan/Details';
import MainDetails from '../components/PreviousLoan/MainDetails';

import useApiRequests from '../hooks/useApiRequests';
import { LoanDetails } from '../interfaces';

import { ReactComponent as moneyBag } from '../assets/icons/moneybag.svg';

import {
  ADDITIONAL_DETAILS_LABELS,
  ADDITIONAL_DETAILS_TITLE,
  LOAN_DETAILS_LABELS,
  LOAN_DETAILS_TITLE,
  LOAN_OFFER_TITLE, LOAN_UPGRADE,
  MAIN_DETAILS_TITLE,
} from '../constants';

const PreviousLoan = () => {
  const { getPreviousLoan, loading, postOpportunity } = useApiRequests();
  const { id } = useParams();
  const [previousLoan, setPreviousLoan] = useState<LoanDetails>();

  useEffect(() => {
    if (id) {
      getPreviousLoan(id)
        .then(data => data && setPreviousLoan(data));
    }
  }, []);

  return (
    <PageLayout header={LOAN_OFFER_TITLE} loading={loading}>
      <div className='relative flex flex-col'>
        {previousLoan && (
          <div className='relative'>
            <div className='flex flex-col flex-grow mt-16 mb-6 mx-6'>
              <AdviceCard Img={moneyBag} description={LOAN_UPGRADE.description} handleButton={async () => postOpportunity()}>
                <AdviceCard.Header title={LOAN_UPGRADE.title} />
              </AdviceCard>
              <span className='font-semibold mt-[46px]'>{`${MAIN_DETAILS_TITLE} ${previousLoan?.fundingOrg}`}</span>
              <MainDetails data={previousLoan!} />
              <div className='mt-10'>
                <span className='block font-semibold text-small mb-1'>{LOAN_DETAILS_TITLE}</span>
                <Details data={previousLoan!} labels={LOAN_DETAILS_LABELS} />
              </div>
              <Collapse label={ADDITIONAL_DETAILS_TITLE} type='info'>
                <div className='mt-5'>
                  <Details data={previousLoan!} labels={ADDITIONAL_DETAILS_LABELS} />
                </div>
              </Collapse>
            </div>
          </div>
        )}
      </div>

    </PageLayout>
  );
};

export default PreviousLoan;
