import classNames from 'classnames';

import { userStore } from '../store';

import { ReactComponent as ProfileBackground } from '../assets/icons/profileIconBackground.svg';
import { ReactComponent as UserIcon } from '../assets/icons/user.svg';

interface IProfileIcon {
    type?: 'primary' | 'menu'
}
const ProfileIcon = ({ type = 'primary' }:IProfileIcon) => {
  const { user } = userStore();

  const containerProfile = classNames('relative', {
    'h-[86px] w-[86px]': type === 'primary' || !user,
    'h-[62px] w-[62px]': type !== 'primary' && user,
    'rounded-full border-white border-[3px]': true,
  });

  const textProfile = classNames('absolute', {
    'text-base': type === 'primary',
    'text-[20px]': type !== 'primary',
    'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 m-auto text-white': true,
  });

  return (
    <div className={containerProfile}>
      <ProfileBackground className='w-full h-full' />
      <div className={textProfile}>
        {user ? user.initials : <UserIcon className='w-full h-full' />}
      </div>
    </div>
  );
};
export default ProfileIcon;
