import { NO_DATA } from '../constants';

export default function extractTextFromHTML(htmlString: unknown) {
  if (typeof htmlString !== 'string') {
    return NO_DATA;
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return doc.body.textContent || '';
}
