import { forwardRef, InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string
  placeholder: string
  errorMessage?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(({
  errorMessage = '', id, placeholder, value, ...inputProps
}, ref) => (
  <div className='relative'>
    <div className='relative text-start'>
      <input
        className={`block text-right direction-rtl box-border px-3 w-full min-h-14 py-4 rounded-lg border appearance-none focus:outline-none focus:ring-0 text-sm bg-white disabled:bg-gray-100
                ${errorMessage ? 'focus:border-red-600 border-red-600' : 'border-[#D9D9D9] focus:border-blue-600'} peer`}
        id={id}
        placeholder=' '
        type='text'
        {...inputProps}
        ref={ref}
        value={value || ''}
      />
      <label
        className={`absolute mx-[13px] px-1 transition-all duration-300 transform -translate-y-4 top-2 z-10 origin-[0] pointer-events-none -right-1 text-xs bg-white 
                        peer-placeholder-shown:scale-100w peer-placeholder-shown:right-0 peer-placeholder-shown:text-sm peer-placeholder-shown:text-[#A8A8A8]
                        peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2
                        peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:-right-1 peer-focus:text-xs
                        ${errorMessage ? 'peer-focus:text-red-600 text-red-600' : 'peer-focus:text-blue-600 text-blue-600'}`}
        htmlFor={id}
      >
        {placeholder}
      </label>
    </div>
    {errorMessage ? <div className='absolute text-xs mt-1 mr-3 text-red-600'>{errorMessage}</div> : undefined}
  </div>
));

Input.displayName = 'Input';

export default Input;
