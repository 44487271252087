import { IPrivacyPolicy } from '../../interfaces';

export const loginPageText = {
  phone: {
    title: 'כניסה',
    subtitle:
  'כדי להיכנס לשירות יש להקליד מספר טלפון נייד ונשלח לך קוד אימות.',
    input_title:
  'טלפון נייד',
    next_button:
  'המשך',
    invalid_content:
  'הנתון שהוכנס לא תקין, אנא הכנס בשנית',
  },
  otp: {
    title: 'אימות משתמש',
    subtitle:
  'ברגעים אלו נשלח אליכם קוד אימות לנייד, אנא הזינו אותו כאן',
    next_button:
  'המשך',
    didnt_receive_code:
  'לא קיבלת קוד?',
    send_code_again:
  'שליחת קוד חדש',
    resend_otp_error:
    'תוכל/י לשלוח קוד מחדש בעוד',
    invalid_content:
  'קוד לא תקין, אנא נסה שנית',
    blocked_content:
  'יש לך קוד זמין! \n חכה שהזמן ייגמר כדי לנסות שוב',
  },
  start: {
    hi: 'היי,',
    title: 'אני היועץ הדיגיטלי שלך. נעים להכיר.',
    lets_start_button: 'בואו נתחיל',
  },
} as const;

export const PRIVACY_POLICY = {
  content: 'אני קראתי ומסכים ל',
  link: 'מדיניות הפרטיות',
};

export const PRIVACY_POLICY_POPUP: {
  title: string,
  subTitle: string,
  sections: IPrivacyPolicy[]
} = {
  title: 'מדיניות הפרטיות',
  subTitle: 'אנא עבור על המסמך המשפטי וחתום בסופו על מנת שנוכל לשלוח את פרטיך',
  sections: [{
    content: 'שירות "_" (להלן: "השירות"/ "היישומון") מופעל על ידי חברת מימון קול השקעות ואשראי בע"מ (להלן: "החברה") הפרטיות של המשתמשים בשירותי החברה חשובה לנו מאוד,'
      + ' ואנחנו מחויבים לשמירה עליה, מדיניות זו מסדירה את האופן בו החברה עושה שימוש במידע אותו היא אוספת בשירות.',
  },
  {
    content: 'משמעות המונח "מידע" במדיניות זו הוא כל מידע הנאסף על ידי החברה או נמסר לה על ידי המשתמש או מי מטעמו,'
      + ' וזאת במסגרת קבלת השירות או שימוש המשתמש בשירות או בשירותי החברה, שבאמצעותו ניתן לזהות את המשתמש וכל מידע המוגן על פי חוק הגנת הפרטיות, התשמ"א – 1981 (להלן: "החוק").',
  },
  {
    content: 'המידע ישמר במאגר המידע של החברה ויעשה בו שימוש למטרות המורטות במדיניות זו.',
  },
  {
    content: 'מסירת המידע לחברה אינה נובעת מכל חובה חוקית ותעשה בהסכמתו וברצונו החופשי של המשתמש, בעצם השימוש בשירותי החברה המשתמש מסכים לאיסוף, עיבוד, שמירה והעברת המידע והכל בהתאם למדיניות זו.',
  },
  {
    content: 'החברה תאסוף את המידע על המשתמש במקרים הבאים:',
    subContent: [
      {
        content: 'כאשר הוא ימסר על ידי המתשמש בעת רישום ושימוש בשירות (גם במקרים בהם המשתמש לא השלים את הליך הרישום).',
      },
      {
        content: 'מידע שנמסר במסגרת השימוש בשירות ובשירותי החברה.',
      },
      {
        content: 'מידע שיתקבל אצל החברה בקשר עם השימוש בשירות וכן מידע שיתקבל ו/או יגיע לרשותה של החברה אודות ובקשר עם המשתמש.',
      },
      {
        content: 'מידע שיגיע ממסמכים, הודעות, התכתבויות, הצהרות ושיחות בקבלת שירות מן החברה בכל אחד מאמצעי התקשורת, בין אם בעל פה ובין אם בכתב, בין עם באמצעות מערכת דיגיטלית ובין עם באמצעות שיח עם נציג אנושי.',
      },
      {
        content: 'מידע שמתקבל לאחר מתן הסכמה למסירת נתוני אשראי מבנק ישראל.',
      },
      {
        content: 'מידע שנמסר בהרשמה לרשימת התפוצה של החברה, הניוזלטר.',
      },
      {
        content: 'מידע שנאסף בעת שימוש בשירות.',
      },
    ],
  },
  {
    content: 'החברה אוספת את המידע במקרים ועושה בו שימוש לצורך המטרות הבאות, כולן או חלקן:',
    subContent: [
      {
        content: 'בכדי ליצור קשר עם המשתמש.',
      },
      {
        content: 'בכדי לספק את שירותי החברה או מי מטעמה למשתמש.',
      },
      {
        content: 'לצרכים פנימיים של החברה.',
      },
      {
        content: 'בכדי לפתח, לשפר, לתחזק, לנתר ולתפעל את השירות.',
      },
      {
        content: 'בכדי לאפשר ביצוע פעולות באמצעות האתר על ידי המשתמש.',
      },
      {
        content: 'בכדי להפעיל ולנהל את שירותי החברה.',
      },
      {
        content: 'בכדי לנהל ולפתח את מערכי השיווק, פרסום, עידוד וקידום מכירות.',
      },
      {
        content: 'בכדי לפתח, לחקור ולנתר מודלים פיננסיים וסטטיסטיים.',
      },
      {
        content: 'בכדי להעביר מידע לצד שלישי למתן שירותים משלימים ו/או אגביים ו/או נוספים לשירותי החברה ו/או לנותני שירותים לחברה.',
      },
      {
        content: 'בכדי להתאים את הפרסומים והמודעות שיוצגו בפני המשתמש בתוך השירות ו/או ברחבי האינטרנט בכדי שיהיו עשויים להתאים להעדפותיו.',
      },
      {
        content: 'בכדי לעשות שימוש בדיוור ישיר כהגדרתו בחוק.',
      },
      {
        content: 'בכדי לערוך סקרים.',
      },
      {
        content: 'בכדי לנתח ולערוך פרופיל משתמש.',
      },
      {
        content: 'בכדי לשלוח ולהמציא למשתמש דברי פרסומת, הטבות, מבצעים, מידע שיווקי, הנחות ומידע על מוצרים ושירותים.',
      },
      {
        content: 'בכדי לאכוף את הוראות תנאי השימוש, מדיניות הפרטיות, וכל הסכם בכתב ו/או בפעל פנה בין המשתמש לחברה.',
      },
      {
        content: 'בכדי להתגונן מפני דרישות, טענות ותביעות כנגד החברה, מי מטעמה וצדדים שלישיים.',
      },
      {
        content: 'לשם עמידה בהוראות כל חוק ודין.',
      },
    ],
  },
  {
    content: 'סוגי המידע, כולם או חלקם שאוספת החברה אודות המשתמש:',
    subContent: [{
      content: 'שם מלא, תאריך לידה, פרטי התקשרות, כתובת, מס\' מזהה, צילום תעודה מזהה, מצב אישי, מצב משפחתי, מעמד אישי, מעמד חוקי, דו"ח נתוני אשראי, טלפון, טלפון נייד, ד. אלקטרוני, הקלטת שיחות,'
        + ' מידע פיננסי, העדפות צרכניות, תנועות עו"ש, נתוני מכשיר, IP, מערכת הפעלה, דפדפן, רזולוציה, מיקום, שירותים שצרך ושצורך המשתמש, מידע הנדרש לחברה לשם הענקת השירות, וכל מידע אחר שיימסר לחברה על ידי המשתמש או מכל גורם אחר על המשתמש.',
    }],
  },
  {
    content: 'חשיפת פרטים אישיים:',
    subContent: [
      {
        content: 'החברה עשויה למסור את הפרטים האישיים שלכם לכל אחד מהעובדים, המנהלים, המבטחים, היועצים המקצועיים, הסוכנים, הספקים, או קבלני המשנה שלה, במידה סבירה וכנדרש למטרות המצוינות במדיניות זו.',
      },
      {
        content: 'החברה עשויה למסור את הפרטים האישיים שלכם לכל חברה בקבוצת החברות שלנו, לרבות חברות בת, חברות גג, חברות אחות, וכל חברה קשורה במידה סבירה וכנדרש למטרות המצוינות במדיניות זו.',
      },
      {
        content: 'החברה עשויה למסור את הפרטים האישיים שלכם:',
        subContent: [
          {
            content: 'לפי מה על פי חוק.',
          },
          {
            content: 'בהקשר של כל הליך משפטי קיים או עתידי.',
          },
          {
            content: 'בכדי לבסס, להפעיל, או להגן על זכויותינו המשפטיות (כולל אספקה של מידע לאחרים למטרות מניעת הונאה והפחתה של סיכון אשראי.',
          },
          {
            content: 'לרוכש או רוכש פוטנציאלי של כל עסק או רכוש שהחברה רוכשת או שוקלת לרכוש.',
          },
          {
            content: 'לכל אדם שהחברה מאמינה במידה סבירה שעשוי לפנות לבית דין או לכל רשות מוסמכת לצורך מסירה של הפרטים האישיים בהם סביר לדעתנו שאותו בית דין או רשות יורה על מסירה של פרטים אישיים אלה.',
          },
          {
            content: 'למעט על פי הכתוב במדיניות זו, אנו לא נספק את הפרטים האישיים שלכם לצד ג’.',
          },
        ],
      },
    ],
  },
  {
    content: 'העברות נתונים בינלאומיות:',
    subContent: [
      {
        content: 'ייתכן שמידע שאנחנו אוספים יאוחסן, יעובד, ויועבר בין כל אחת מהמדינות בהן אנו פועלים, על מנת לאפשר לנו להשתמש במידע בהתאם למדיניות זו.',
      },
      {
        content: 'ייתכן שמידע שאנחנו אוספים יועבר למדינות הבאות, להן אין חוקי הגנת נתונים התואמים את אלה הפועלים באזור הכלכלי האירופי: ארצות הברית של אמריקה, רוסיה, יפן, סין, והודו.',
      },
      {
        content: 'פרטים אישיים שאתם מפרסמים באתר שלנו או מוסרים לפרסום באתר שלנו עשויים להיות זמינים, באמצעות האינטרנט, מסביב לעולם. אנחנו לא יכולים למנוע את השימוש או השימוש לרעה במידע הזה בידי אחרים.',
      },
      {
        content: 'אתם מביעים הסכמה להעברות הפרטים האישיים המתוארות בסעיף ו’ זה.',
      },
    ],
  },
  {
    content: 'במהלך השימוש בשירות, החברה תאסוף מידע לא מזהה אודות משתמשים, אשר אינו חושף את זהותם האישית של המשתמשים.',
  },
  {
    content: 'אבטחת פרטים אישיים על ידי החברה:',
    subContent: [
      {
        content: 'החברה תנקוט משנה זהירות ארגוני וטכני סביר על מנת למנוע את האבדן, שימוש לרעה, או שינוי של הפרטים האישיים של המשתמש.החברה תנקוט משנה זהירות ארגוני וטכני סביר על מנת למנוע את האבדן, שימוש לרעה, או שינוי של הפרטים האישיים של המשתמש.',
      },
      {
        content: 'החברה תאחסן את כל הפרטים האישיים ששל המשתמש בשרתים מאובטחים (המוגנים בסיסמא ובחומת אש).',
      },
      {
        content: 'כל העברה הכספית ממוחשבת המבוצעת דרך שירותי החברה תהא מוגנת באמצעות טכנולוגיית הצפנה.',
      },
      {
        content: 'המשתמש יודע ומכיר בכך שכל \\ידור של מידע על פני האינטרנט אינו מאובטח מעצם היותו, והחברה לא יכולה להבטיח שנתונים שנשלחים דרך האינטרנט יהיו מאובטחים.',
      },
      {
        content: 'המשתמש אחראי על שמירת פרטי ההתחברות בו הוא מקבל גישה לשירותי החברה.',
      },
    ],
  },
  {
    content: 'החברה עשויה לעדכן מדיניות זו מעת לעת באמצעות פרסום של גרסה חדשה בשירות ו/או באתר החברה.'
      + ' על המשתמש לבדוק את עמוד זה מדי פעם בפעם על מנת לוודא כל שינוי שנעשה במדיניות זו. החברה עשויה להודיע למשתמש על שינויים במדיניות זו בהודעת דואר אלקטרוני או דרך מערכת ההודעות הפרטיות בשירות שלנו.',
  },
  {
    content: 'זכויות המשתמש:',
    subContent: [
      {
        content: 'המשתמש יכול להורות לחברה לספק כל פרט מפרטיו האישיים שהחברה מחזיקה הנוגע אליו, אספקת פרטים אלה תהיה כפופה לתנאים הבאים:',
        subContent: [
          {
            content: 'תשלום של עמלה בהתאם לחוק.',
          },
          {
            content: 'אספקה של ראיות הולמות עבור זהות המשתמש - צילום שלתעודה מזהה באישור נוטריון, יחד עם עותק מקורי של חשבון שירות בו ניתן לראות את הכתובת הנוכחית של המשתמש.',
          },
          {
            content: 'החברה עשויה לסרב למסור פרטים אישיים עד למידה המורשית על פי חוק.',
          },
          {
            content: 'המשתמש רשאי להורות לחברה בכל עת לא לעבד את פרטיו האישיים לצורכי שיווק.',
          },
          {
            content: 'בפועל, בדרך כלל יבטא המשתמש הסכמה מראש לשימוש החברה בפרטיו האישיים למטרות שיווק, או שהחברה תספק למשתמש אפשרות לבחור שלא יעשה שימוש למטרות שיווקיות בפרטיו האישיים.',
          },
        ],
      },
    ],
  },
  {
    content: 'שירותים מצד שלישי:',
    subContent: [
      {
        content: 'השירות כולל קישורים חיצוניים, ופרטים על, שירותי ו/או אתרים צד ג’. לחברה אין שליטה על, ואיננה אחראית על, מדיניות הפרטיות והנהלים של כל צד ג’.',
      },
    ],
  },
  {
    content: 'עדכון מידע:',
    subContent: [
      {
        content: 'ככל ונדרש, המשתמש יודיע לחברה אם יש צורך לתקן או לעדכן את הפרטים האישיים שמוחזקים על ידי החברה.',
      },
    ],
  },
  ],
};

export const POPUP_ERRORS = {
  tooManyRequests: 'נראה שכבר ביקשת קוד  \n נסה שוב בעוד',
  serverError: 'משהו השתבש! \n נסו שוב מאוחר יותר',
  timeIsUp: 'הזמן עבר! הקוד לא זמין יותר \n נסו להיכנס מחדש',
};

export const BLOCK_TIME = 60;
export const PHONE_LENGTH = 10;
