import { Link } from 'react-router-dom';

import ContactUsVia from '../components/Generic/ContactUsVia';

import { LOGIN, notRegisteredText } from '../constants';

const NotRegisteredPage = () => (
  <div className='h-full'>
    <div className='mx-[28px] flex flex-col h-full justify-between py-[64px]'>

      <div className='flex flex-col gap-6 text-center justify-center text-small leading-small mt-[150px]'>
        <p className='font-semibold'>{notRegisteredText.text}</p>
        <ContactUsVia />
      </div>

      <Link
        className='flex justify-center items-center w-full h-[48px] rounded-[29px] bg-buttonBlue text-white font-bold flex-end'
        to={LOGIN}
        type='submit'
      >
        {notRegisteredText.button}
      </Link>
    </div>
  </div>
);

export default NotRegisteredPage;
