import { toast, ToastOptions, TypeOptions } from 'react-toastify';

export const uploadToast = (message: string, type: TypeOptions, options?: ToastOptions) => {
  toast(message, {
    position: 'bottom-center',
    theme: 'dark',
    hideProgressBar: true,
    type,
    rtl: true,
    autoClose: 3000,
    pauseOnFocusLoss: false,
    className: `w-11/12 mx-auto mb-8 rounded-md text-center font-[heebo] shadow-card 
      ${type === 'success' ? 'bg-[#66DFA5]' : ''} 
      ${type === 'error' ? 'bg-[#c82323]' : ''}`,
    ...options,
  });
};

export const updateDetails = (type: ToastOptions['type'], message: string, options?: Omit<ToastOptions, 'type'>) => {
  toast(message, {
    position: 'top-center',
    theme: 'colored',
    rtl: true,
    autoClose: 3000,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    className: 'w-11/12 mx-auto mt-10 rounded-md text-center font-[heebo] shadow-card bg-[#66DFA5]',
    type,
    ...options,
  });
};
