import {
  ComponentType, Dispatch, JSX, ReactElement, SetStateAction, useRef, useState,
} from 'react';
import Slider, { Settings, SwipeDirection } from 'react-slick';

import SlideDot from './SlideDot';

import useApiRequests from '../../hooks/useApiRequests';

import { BUTTONS } from '../../constants';

interface CardCarouselProps<T> {
  content: T[]
  setFinished: Dispatch<SetStateAction<boolean>>
  CarouselCardComponent: ComponentType<T & { lastSlider: boolean }>
  className?: string
}
const WalkthroughSlider = <T, > ({
  CarouselCardComponent, className, content, setFinished,
}:CardCarouselProps<T>) => {
  const { putUseMobileApp } = useApiRequests();
  const slideRef = useRef<Slider | null>(null);
  const [slideIndex, setSlideIndex] = useState(content.length - 1);

  const nextSlide = (): void => {
    if (slideIndex - 1 < 0) setFinished(true);
    slideRef.current?.slickGoTo(slideIndex - 1);
  };

  const settings: Settings = {
    appendDots: (dots: ReactElement[]): JSX.Element => (
      <div className='flex flex-row-reverse'>
        {dots.map((dot, index) => (
          <SlideDot
            key={index}
            activeIndex={slideIndex}
            index={index}
          />
        ))}
      </div>
    ),
    arrows: false,
    rtl: true,
    dots: true,
    slidesToShow: 1,
    infinite: false,
    initialSlide: 0,
    swipe: true,
    className: `flex flex-grow text-center ${className ?? ''}`,
    swipeEvent(swipeDirection: SwipeDirection) {
      return swipeDirection === 'right'
        ? slideRef.current?.slickGoTo(slideIndex - 1)
        : slideRef.current?.slickGoTo(slideIndex + 1);
    },
    async beforeChange(current, next) {
      try {
        if (current === 0 && next === 0) {
          await putUseMobileApp();
        }
        return setSlideIndex(next);
      } catch (error) {
        console.error(error);
        return setFinished(true);
      }
    },
  };

  const skipWalkthrough = async () => {
    await putUseMobileApp();
    setFinished(true);
  };

  return (
    <div className='flex flex-col flex-grow'>
      <Slider
        ref={slideRef}
        {...settings}
      >
        {content.map((item: any, index: number) => <CarouselCardComponent key={`walkthrough-slide-${index}`} {...item} lastSlider={index === content.length - 1} />)}
      </Slider>
      <div className='flex justify-between text-buttonBlue items-center mb-5 font-bold mx-9 z-50'>
        <button type='button' onClick={skipWalkthrough}>
          {BUTTONS.SKIP}
        </button>
        <button type='button' onClick={nextSlide}>
          {slideIndex === 0 ? BUTTONS.FINISH : BUTTONS.NEXT}
        </button>
      </div>
    </div>
  );
};
export default WalkthroughSlider;
