import { IOptionsMenuSectionProps } from '../components/Main/OptionsMenu/OptionsMenuSection';

import { ReactComponent as Proposal } from '../assets/icons/proposal.svg';

import { INFO_SECTION } from '../constants';

export const menuSectionOptions: IOptionsMenuSectionProps[] = [
  {
    Icon: Proposal,
    text: INFO_SECTION.myLoan,
    navigationPath: '/prev-loans',
  },
];
