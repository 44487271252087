import { Link } from 'react-router-dom';

import { Doc } from '../../../interfaces';
import { uiLogicStore } from '../../../store';
import { dateTimeFormat } from '../../../utils/dateTimeFormat';

import { ReactComponent as Download } from '../../../assets/icons/download.svg';

import { FILE_DESCRIPTION } from '../../../constants';

const DocCard = ({
  documentType, modifiedOn, name, url,
}:Doc) => {
  const { isApp } = uiLogicStore();

  const DownloadComponent = {
    web: (
      <Link
        download
        to={url}
      >
        <Download />
      </Link>
    ),
    app: (
      <button
        type='button'
        onClick={() => (window as any).Android.downloadLink(url)}
      >
        <Download />
      </button>
    ),
  };

  const downloadContent = isApp ? DownloadComponent.app : DownloadComponent.web;

  return (
    <div className='flex shadow-card rounded-3xl p-5 justify-between gap-x-3'>
      <div className='overflow-auto'>
        <span className='text-small font-bold text-[#212F61]'>{documentType}</span>
        <div className='text-[12px] mt-1 font-normal'>
          <p className='overflow-ellipsis whitespace-nowrap overflow-hidden'>{`${FILE_DESCRIPTION.fileName}: "${name}"`}</p>
          <p>{`${FILE_DESCRIPTION.fileDate}: ${dateTimeFormat(new Date(modifiedOn))}`}</p>
        </div>
      </div>
      <div className='flex content-end items-center gap-x-3 justify-end'>
        {downloadContent}
      </div>
    </div>
  );
};

export default DocCard;
