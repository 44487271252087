import { create } from 'zustand';

import { LoanRequest, LoanStatus, User } from '../interfaces';

interface IPersonalProfileStore{
  user?: User
  hasBdi: boolean,
  setUser: (user: User) => void
  logOut: () => void
  loanRequests: LoanRequest<LoanStatus>[]
  setLoanRequests: (loanRequests: LoanRequest<LoanStatus>[]) => void
  blockNavigation: boolean
  setBlockNavigation: (blockNavigation: boolean) => void
}

export const userStore = create<IPersonalProfileStore>((set) => ({
  hasBdi: false,
  blockNavigation: false,
  loanRequests: [],
  setUser: (user) => set((state) => ({ ...state, user, blockNavigation: false, hasBdi: !!user.bdiRating })),
  setLoanRequests: (loanRequests) => set((state) => ({ ...state, loanRequests })),
  logOut: () => set((state) => ({ ...state, user: undefined, blockNavigation: true, loanRequests: [], hasBdi: false })),
  setBlockNavigation: (blockNavigation) => set((state) => ({ ...state, blockNavigation })),
}));
