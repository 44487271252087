import { useEffect, useState } from 'react';

import { isMobile } from '../utils/mobileDetaction';

const useIsMobile = () => {
  const [isPhone, setIsPhone] = useState(isMobile());

  useEffect(() => {
    const handleResize = () => {
      const phoneCheck = isMobile();
      if (phoneCheck !== isPhone) {
        setIsPhone(phoneCheck);
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [isPhone]);

  return isPhone;
};
export default useIsMobile;
