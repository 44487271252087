import MobileDetect from 'mobile-detect';

const isSamsungInternet = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('samsungbrowser');
};

export const isMobile = () => {
  const md = new MobileDetect(window.navigator.userAgent);

  return !!md.phone() || isSamsungInternet;
};
