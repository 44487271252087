export function dateTimeFormat(date: Date) {
  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  };

  return new Intl.DateTimeFormat('de-DE', options).format(date);
}

export function isValidDateTimeFormat(str: unknown) {
  if (typeof str !== 'string') {
    return false;
  }
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  return regex.test(str);
}
