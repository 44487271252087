import { LoanDetails, LoanLabels } from '../../interfaces';
import { getValue } from '../../utils/previousLoansUtils';

interface IDetails {
    labels:LoanLabels[]
    data: LoanDetails
}
const Details = ({ data, labels }: IDetails) => (
  <>
    {labels.map(detail => {
      const value = data[detail.keyValue];

      return (
        <div key={detail.keyValue} className='flex justify-between text-[12px] leading-4 py-4 border-b border-b-borderGray'>
          <span>{detail.name}</span>
          <span className='font-semibold'>{getValue(detail, value)}</span>
        </div>
      );
    })}
  </>

);

export default Details;
