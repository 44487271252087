import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Select from '../components/Form/Select/Select';
import PageLayout from '../components/Layout/PageLayout';
import UploadDropzone from '../components/Upload/UploadDropzone';

import useApiRequests from '../hooks/useApiRequests';
import { docsUploadStore } from '../store';
import { convertBase64ToFile } from '../utils/convertBase64ToFile';
import { uploadToast } from '../utils/uploadToast';

import {
  TOAST_MESSAGE,
  UPLOAD, UPLOAD_PAGE_TITLE, UPLOAD_SELECT_PLACEHOLDER,
} from '../constants';

const UploadPage = () => {
  const navigate = useNavigate();
  const { getDocTypes, loading, uploadDoc } = useApiRequests();

  const {
    addFiles,
    clearUpload,
    docsTypes,
    files,
    setDocsTypes,
    setType,
    type,
  } = docsUploadStore(state => state);

  (window as any).onImageSelected = async (base64String: string, fileName: string) => {
    if (base64String && fileName) {
      if (files.length > 0 && !files.every(file => file.name !== fileName)) return;

      const file = convertBase64ToFile(base64String, fileName);

      if (file) addFiles([file]);
      else uploadToast(TOAST_MESSAGE.error, 'error');
    }
  };

  useEffect(() => {
    getDocTypes().then((data) => data && Array.isArray(data) && setDocsTypes(data));

    return () => {
      clearUpload();
    };
  }, []);

  const handleSubmit = async () => {
    const response = await uploadDoc(
          type!.value,
          files,
    );
    if (response === 'created') {
      clearUpload();
      uploadToast(TOAST_MESSAGE.success, 'success');
      return navigate('/personal-profile', { replace: true });
    }
    return uploadToast(TOAST_MESSAGE.error, 'error');
  };

  return (
    <PageLayout header={UPLOAD_PAGE_TITLE} loading={loading}>
      <div className='flex flex-col relative w-4/5 mx-auto pt-10 h-full'>
        <Select
          id='upload-select'
          placeholder={UPLOAD_SELECT_PLACEHOLDER}
          value={type?.value || ''}
          onChange={(e) => {
            const doc = docsTypes.find((docType) => docType?.value === e.target.value);
            setType(doc);
          }}
        >
          <option disabled hidden value='' />
          {docsTypes && docsTypes.map(docType => (
            <option key={`doc-type-${docType.value}`} value={docType.value}>{docType.key}</option>
          ))}
        </Select>
        <UploadDropzone />
        <div className='mt-auto peer-disabled:bg-amber-400 '>
          <button
            className={`bottom-4 w-full bg-buttonBlue text-white rounded-3xl py-3 my-4 px-16
                          disabled:static disabled:opacity-50 peer`}
            disabled={!type || files.length !== 1}
            type='button'
            onClick={handleSubmit}
          >
            {UPLOAD.submit}
          </button>
        </div>
      </div>
    </PageLayout>
  );
};

export default UploadPage;
