import React, { forwardRef } from 'react';

import { ReactComponent as ChevronDown } from '../../../assets/icons/selectChevronDown.svg';

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  id: string
  placeholder: string
  children: React.ReactNode
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(({
  id, placeholder, ...selectProps
}, ref) => {
  const { children, value } = selectProps;

  return (
    <div className='relative text-start'>
      <select
        className='block px-3 w-full py-[18px] bg-transparent rounded-lg border appearance-none bg-white
                  border-[#D9D9D9] focus:outline-none focus:ring-0 text-sm peer'
        id={id}
        value={value}
        {...selectProps}
        ref={ref}
      >
        {children}
      </select>
      <label
        className={`absolute mx-[13px] px-1 transition-all duration-300 transform z-10 origin-[0] pointer-events-none bg-white
                    ${!value ? 'scale-100w right-0 text-sm text-lightGrey -translate-y-1/2 top-1/2' : '-translate-y-4 top-2 -right-1 text-xs text-blue-600 '} 
                    peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:-right-1 peer-focus:text-xs`}
        htmlFor={id}
      >
        {placeholder}
      </label>
      <ChevronDown className='absolute left-3 top-1/2 -translate-y-1/2 pointer-events-none' />
    </div>
  );
});

export default Select;
