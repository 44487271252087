import { CreditRatingDetailColor, ReportEssence, ReportEssenceByCategory } from '../interfaces';

import { CREDIT_RATING_CATEGORIES, NO_REPORT_ESSENCE_DESC, REPORT_ESSENCE_TITLE } from '../constants';

export const reportEssenceByCategories = (reportEssence: ReportEssence) => Object.entries(REPORT_ESSENCE_TITLE)
  .reduce((previousValue: ReportEssenceByCategory, [reportEssenceKey, reportEssenceTitle]) => {
    const reportItemColor = reportEssence[reportEssenceKey as keyof typeof reportEssence] as CreditRatingDetailColor;
    const category = CREDIT_RATING_CATEGORIES.find(category => category.color === reportItemColor);

    if (category) {
      return {
        ...previousValue,
        [category.id]: {
          icon: category.icon,
          title: category.title,
          order: category.order,
          content: [...(previousValue[category.id]?.content ?? []), {
            title: reportEssenceTitle,
            color: reportItemColor,
            description: reportEssence[(`${reportEssenceKey}Desc`) as keyof typeof reportEssence]?.toString() ?? NO_REPORT_ESSENCE_DESC,
          }],
        },
      };
    }
    return previousValue;
  }, {});
