export const REJECTED_REQUEST_HEADER = 'הסטטוס בקשת הלוואה שלך לא אושרה';

export const REJECTED_DESCRIPTION = 'תודה שפנית אלינו בבקשה לקבלת הלוואה. לאחר בדיקה יסודית של בקשתך, לצערנו עלינו להודיעך כי הבקשה להלוואה לא אושרה בשלב זה.';

export const SOLUTIONS = 'אבל אל דאגה, יש לי פתרונות במיוחד בשבילך';

export const ADVISER = 'פנייה לנציג';

export const ASSISTANT = {
  received: 'נא להיות זמין בשעות הקרובות לשיחה עם נציג להמשך טיפול בתיק ההלוואה',
  connectionWord: 'או',
  call: 'צור איתנו קשר בטלפון',
  phoneNumber: '*2393',
} as const;

export const ASSISTANT_INFO = 'הטיפול בתיק עבר לטיפול במחלקה הפרונטלית של מימון כל, הינך נדרש לספק מסמכים נוספים: עובר ושב, שומת מס 2022.';

export const ADD_DOCS = 'הוספת מסמכים';
