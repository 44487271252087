import { ReactElement } from 'react';

import Back from '../Generic/Back';
import Loading from '../Loading/Loading';

import 'react-toastify/dist/ReactToastify.css';

interface ILayout {
    header: ReactElement | string
    children: ReactElement
    loading?: boolean
}

const PageLayout = ({
  children, header, loading,
}: ILayout) => (
  <div className='flex flex-col flex-grow overscroll-none overflow-auto'>
    {loading && <Loading />}
    <Back />
    {
        typeof header === 'string'
          ? <span className='mt-5 mr-6 text-white font-bold text-xl'>{header}</span>
          : header
    }
    <div className='flex flex-col flex-grow bg-white rounded-tr-[32px] rounded-tl-[32px] mt-10'>
      {children}
    </div>
  </div>
);

export default PageLayout;
