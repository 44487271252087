import { LoanDetails } from '../../interfaces';

import { MAIN_DETAILS_LABELS } from '../../constants';

interface IMainDetails {
    data: LoanDetails
}

const MainDetails = ({ data }: IMainDetails) => (
  <div className='grid grid-cols-3 gap-x-5 text-center mt-6'>
    {MAIN_DETAILS_LABELS.map((detail, index) => {
      const value = data[detail.keyValue] || 0;
      return (
        <div key={`prev-loan-label-${index} `} className='flex flex-col justify-center border-[1.5px] rounded-xl border-buttonBlue p-2'>
          <p className='font-semibold'>{detail.isMoney ? `₪${value.toLocaleString()}` : `P + ${value}%`}</p>
          <p className='text-[12px]'>{detail.name}</p>
        </div>
      );
    })}
  </div>
);

export default MainDetails;
