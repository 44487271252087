import { create } from 'zustand';

import { LoanDetails, LoanRequest, LoanStatus, LoanType, Recommendations, ReportEssence } from '../interfaces';

interface IBdiStore{
  modifiedOn: string
  setModifiedOn:(modifiedOn: string) => void
  loanRequest?: LoanRequest<LoanStatus>
  setLoanRequest: (loanRequest: LoanRequest<LoanStatus>) => void
  recommendations: Recommendations[]
  setRecommendations: (recommendations: Recommendations[]) => void
  loans: LoanType[]
  setLoans: (loans: LoanType[]) => void
  ratingReasons: string[]
  setRatingReasons: (ratingReasons: string[]) => void
  reportEssences?: ReportEssence
  setReportEssences: (reportEssences: ReportEssence) => void
  previousLoans: LoanDetails[]
  setPreviousLoans: (previousLoans: LoanDetails[]) => void
  reset: () => void
}

const defaultStoreData = {
  loans: [],
  recommendations: [],
  ratingReasons: [],
  reportEssences: undefined,
  modifiedOn: '',
  previousLoans: [],
};

export const bdiStore = create<IBdiStore>((set) => ({
  ...defaultStoreData,
  setModifiedOn: (modifiedOn) => set((state) => ({ ...state, modifiedOn })),
  setLoanRequest: (loansRequest) => set((state) => ({ ...state, loansRequest })),
  setRecommendations: (recommendations) => set((state) => ({ ...state, recommendations })),
  setLoans: (loans) => set((state) => ({ ...state, loans })),
  setRatingReasons: (ratingReasons) => set((state) => ({ ...state, ratingReasons })),
  setReportEssences: (reportEssences) => set((state) => ({ ...state, reportEssences })),
  setPreviousLoans: (previousLoans) => set((state) => ({ ...state, previousLoans })),
  reset: () => set((state) => ({ ...state, ...defaultStoreData })),
}));
