import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import LoadingSpinner from '../../Loading/LoadingSpinner';
import DocCard from './DocCard';
import EmptyState from './EmptyState';

import useApiRequests from '../../../hooks/useApiRequests';
import { personalProfileStore } from '../../../store';

import { ReactComponent as PlusDoc } from '../../../assets/icons/plusDoc.svg';

import { UPLOAD_BUTTON } from '../../../constants';

const Docs = () => {
  const { getDocs, loading } = useApiRequests();
  const { docs, setDocs } = personalProfileStore();

  useEffect(() => {
    getDocs().then((data) => data && Array.isArray(data) && setDocs(data));
  }, []);

  const DocsComponents = {
    noDocs: <EmptyState />,
    docs: (
      <div className='flex flex-col gap-7 my-5'>
        {docs.map(((doc, index) => (
          <DocCard key={`doc-${index}`} {...doc} />
        )))}
      </div>)
    ,
  };
  const docsContent = docs.length > 0 ? DocsComponents.docs : DocsComponents.noDocs;

  return (
    <div className='flex flex-col py-9'>
      <Link
        className='flex justify-center gap-x-1 w-full py-4 rounded-3xl bg-buttonBlue text-white font-bold items-center'
        to='/upload'
      >
        <PlusDoc />
        {UPLOAD_BUTTON}
      </Link>

      {loading ? (
        <div className='mx-auto mt-10'>
          <LoadingSpinner />
        </div>
      ) : (
        <div className='mt-6'>
          {docsContent}
        </div>
      )}
    </div>
  );
};

export default Docs;
