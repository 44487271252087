import Collapse from './Collapse';

import { InfoItem } from '../../interfaces';
import extractTextFromHTML from '../../utils/extractTextFromHTML';

import { ReactComponent as Minus } from '../../assets/icons/minus.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';

interface IContentCollapse {
  list: InfoItem[];
}

const CollapsibleInfoList = ({ list }: IContentCollapse) => (
  <>
    {list.map(({ description, id, subject }) => (
      <Collapse
        key={`faq-${id}`}
        customIcons={{
          open: Plus,
          close: Minus,
        }}
        label={subject}
        type='info'
      >
        <div className='mt-4 text-sm leading-5'>{extractTextFromHTML(description)}</div>
      </Collapse>
    ))}

  </>
);

export default CollapsibleInfoList;
