import { CUSTOMER_SERVICE } from '../../constants';

const CustomerServiceHeader = () => (
  <div className='mt-10'>
    <span className='font-bold'>
      {CUSTOMER_SERVICE.title}
    </span>
    <p className='text-[#212F61] font-medium text-sm'>{CUSTOMER_SERVICE.description}</p>
  </div>
);

export default CustomerServiceHeader;
