import { privacyPolicySection } from '../../utils/privacyPolicyUtil';

import { PRIVACY_POLICY_POPUP } from '../../constants';

const PrivacyPolicyPopup = () => (
  <div className='text-right'>
    <div className='font-bold'>{PRIVACY_POLICY_POPUP.title}</div>
    <div className='mt-1 text-sm leading-4'>{PRIVACY_POLICY_POPUP.subTitle}</div>
    <ul className='
        flex flex-col gap-y-2 my-4 text-lightGrey text-small whitespace-pre-line h-[50vh] p-0'
    >
      {PRIVACY_POLICY_POPUP.sections.map((section, index) => privacyPolicySection(section, index))}
    </ul>
  </div>
);

export default PrivacyPolicyPopup;
