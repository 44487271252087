import { useEffect, useState } from 'react';

import CollapsibleInfoList from '../components/Collapse/CollapsibleInfoList';
import PageLayout from '../components/Layout/PageLayout';

import useApiRequests from '../hooks/useApiRequests';
import { InfoItem } from '../interfaces';

import { INFO_CENTER_TITLE, INFO_EMPTY_STATE } from '../constants';

const FaqPage = () => {
  const { getInfoCenter, loading } = useApiRequests();
  const [infoData, setInfoData] = useState<InfoItem[]>([]);

  useEffect(() => {
    getInfoCenter().then(data => setInfoData(data ?? []));
  }, []);

  return (
    <PageLayout header={INFO_CENTER_TITLE} loading={loading}>
      <div className='flex flex-col w-10/12 mx-auto mt-5 pb-4'>
        {infoData.length > 0
          ? <CollapsibleInfoList list={infoData} />
          : <span className='text-center text-gray-300 text-small'>{INFO_EMPTY_STATE}</span>}
      </div>
    </PageLayout>
  );
};

export default FaqPage;
