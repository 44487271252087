import { Link } from 'react-router-dom';

import { ReactComponent as Phone } from '../../assets/icons/assistantPhone.svg';

import { ADD_DOCS, ASSISTANT, ASSISTANT_INFO } from '../../constants';

const Assistant = () => (
  <div className='flex flex-col flex-grow text-center'>
    <div className='m-auto'>
      <Phone className='mx-auto' />
      <div className='mt-5 max-w-64 mx-auto'>
        <p>{ASSISTANT.received}</p>
        <p>{ASSISTANT.connectionWord}</p>
        <p>
          {ASSISTANT.call}
          <a className='inline-flex text-buttonBlue direction-ltr mr-1' href={`tel:${ASSISTANT.phoneNumber}`}>
            {` ${ASSISTANT.phoneNumber}`}
          </a>
        </p>
      </div>
    </div>
    <div className='mt-auto'>
      <div className='bg-[#b9e7f44d] py-5 px-3 rounded-md'>
        {ASSISTANT_INFO}
      </div>
      <Link
        className='flex justify-center gap-x-1 w-full py-4 rounded-3xl bg-buttonBlue text-white font-bold my-14'
        to='/upload'
      >
        {ADD_DOCS}
      </Link>
    </div>
  </div>
);

export default Assistant;
