import { SweetAlertOptions } from 'sweetalert2';

import { LoanRequest, LoanStatus } from '../interfaces';

import firework from '../assets/images/firework.gif';

import { APPROVED_LOAN_REQUEST, mainLoanRequestText } from '../constants';

export const mimoonPopupOptions = ({ customClass, ...options }: SweetAlertOptions): SweetAlertOptions => ({
  showConfirmButton: false,
  showCloseButton: true,
  position: 'center',
  inputAutoFocus: false,
  ...options,
  customClass: typeof customClass === 'string' ? customClass : {
    popup: 'grid w-11/12 text-[#212F61]',
    closeButton: 'text-[#212F61] text-[30px] focus:shadow-none mb-1 pl-[12px] pt-[12px]',
    ...customClass,
  },
});

export const approvedPopupOptions = ({ productName }: LoanRequest<LoanStatus.APPROVED>) => mimoonPopupOptions({
  title: APPROVED_LOAN_REQUEST.title,
  html: `<div class='text-sm text-greyed mt-0'>
                  <span class='ml-1'>${mainLoanRequestText.loan_type}</span>
                  <span class='font-semibold'>${productName}</span>
`,
  footer: APPROVED_LOAN_REQUEST.footer,
  imageUrl: firework,
  customClass: {
    title: 'text-[16px] row-start-2 row-end-2',
    htmlContainer: 'row-start-3 row-end-3',
    footer: 'text-[12px] leading-5 font-normal',
  },
});
