import { create } from 'zustand';

import { DocType } from '../interfaces';

interface IDocStore {
  type?: DocType,
  setType: (type?: DocType) => void
  docsTypes: DocType[]
  setDocsTypes: (docsTypes: DocType[]) => void
  files: File[]
  addFiles: (files: File[]) => void
  removeFile: (file: File) => void
  displayError: boolean
  setDisplayError: (displayError: boolean) => void
  clearUpload: () => void
}

const defaultStoreData = {
  type: undefined,
  files: [],
  docsTypes: [],
  displayError: false,
};

export const docsUploadStore = create<IDocStore>((set, get) => ({
  ...defaultStoreData,
  setType: (type) => set((state) => ({
    ...state,
    type,
    displayError: false,
  })),
  setDocsTypes: (docsTypes) => set((state) => ({
    ...state,
    docsTypes,
  })),
  addFiles: (acceptedFiles) => set((state) => ({
    ...state,
    files: [...state.files, ...acceptedFiles],
    displayError: false,
  })),
  removeFile: (file) => set((state) => ({
    ...state,
    files: state.files.filter(acceptedFile => file.name !== acceptedFile.name),
  })),
  setDisplayError: (displayError) => set((state) => ({ ...state, displayError })),
  clearUpload: () => set((state) => ({
    ...state,
    ...defaultStoreData,
  })),
}));
