export const WALKTHROUGH_HEADER = 'מה היתרונות שלנו?';

export const LAST_SLIDE_HEADER = 'המלצה מהיועץ';
export const WALKTHROUGH_CONTENT = [
  {
    title: 'נסיון',
    description: 'למה אני צריך יועץ? זאת השאלה הנפוצה ביותר שאנו נשאלים. האם אני יכול לגשת לבנקים ולחברות המימון בכוחות עצמי?'
            + ' התשובה היא כן, תוכל/י לגשת לכל בנק ולפנות לכל חברת מימון לבד.'
            + 'אבל... היועצים שלנו בעלי נסיון רב בניהול אלפי תיקים כל שנה, ההכרות שלנו עם המערכת הבנקאית הינה מקיפה ומבוססת על הכרות בעלת נסיון רב שנים.',
  },
  {
    title: 'כח קניה',
    description: ' מימון כל היא חברת יועצים עם כח קניה עצום,'
        + ' אשר במהלך יום עסקים מגישה יותר מ-300 תיקי לקוחות אל מול הגופים הבנקאיים, דבר המקנה לנו קלף מיקוח אדיר אל מול גופי המימון ובסופו של תהליך מוביל להצלחה. ',
  },
  {
    title: 'ידע',
    description: 'כמות ורמת הידע שצברו היועצים והבנת השפה הבנקאית בתהליך ייצוג התיק אל מול הבנק וגופי המימון, בא לידי ביטוי באופן הצגת הדברים והנתונים של כל לקוח, מה שמחזק מאוד את תיק הלקוח.'
        + ' ההתאמות לפי סוג התיק ומורכבותו לאותו גורם בנקאי או חוץ בנקאי, הינו קריטי בכל ניהול תיק לקוח.'
        + ' היועצים מכירים את ההנחיות והנהלים השונים של כל בנק ויודעים לקדם את תיק הלקוח על פי התאמה מלאה לצרכי הלקוח ולסוג הבנק או גוף המימון והכל בהתאם לנהלים שלו.',
  },
  {
    title: 'חסכון',
    description: 'המודל החשוב ביותר עבורנו הוא מודל החיסכון עבור הלקוח. הוכח כי תיקים המנוהלים ע"י יועצים מנוסים, מקבלים תנאים טובים יותר עם חסכון בריבית השנתית, אשר מהווה ללקוח חסכון אדיר עד סיום תקופת ההלוואה. '
        + 'זכרו… הריבית שגובה הבנק או גוף המימון הינה ריבית שנתית וקרוב לוודאי שההלוואה הבאה שלכם הינה למספר שנים טובות קדימה ולכן פריסת החיסכון היא על מספר שנים.',
  },
  {
    title: 'שירות מקצועי',
    description: 'היועצים שלנו לא משאירים אתכם לבד בתהליך להתנהל לבד מול גופי המימון. ליווי מלא מרגע בניית תיק הלוואה ועד קבלת הכסף בחשבון בפועל.',
  },
  {
    title: 'נקודה למחשבה',
    description: 'כפי שלקוח בוחר להיות מיוצג ע"י עו"ד מול מערכת המשפט, למרות שבאופן כללי הוא יכול לייצג את עצמו בכל תיק.\n'
        + 'האם התוצאה תהיה אותה תוצאה? ללא עו"ד מייצג שלמד ומכיר את מערכת המשפט?\n'
        + 'גם במימון כל המערך הינו זהה:\n'
        + 'יועץ טוב ומנוסה, בעל הקשרים, היכולות והניסיון עם השפה הבנקאית, יביא תוצאה אחרת -לרוב הטובה ביותר עבור הלקוח.',
  },
];

export const BUTTONS = {
  NEXT: 'הבא',
  SKIP: 'דלג',
  FINISH: 'סיום',
};
