import { FunctionComponent, ReactNode, SVGProps } from 'react';
import { Link } from 'react-router-dom';

import { ADDITIONAL_INFO, CONSULTANT } from '../constants';

interface IAdCardProps {
  children: ReactNode;
  description: string;
  Img: FunctionComponent<SVGProps<SVGSVGElement>>
  buttonText?: string
  handleButton?: () => void
}

type IAdCardHeaderProps = { title: string; children?: never }
  | { title?: never; children: ReactNode };

const AdviceCard = (
  {
    buttonText, children, description, handleButton, Img,
  }: IAdCardProps,
) => (
  <div className='bg-[#4e95fd] bg-repeat-round text-white rounded-[21px] border-[1px] border-solid border-white shadow-card'>
    <div className='bg-blur-card-wrapper rounded-[21px] text-[12px] pt-[26px] px-4 pb-3 bg-cover bg-no-repeat'>
      <div className='flex gap-x-3 font-normal items-center text-sm pl-4'>
        <Img />
        {children}
      </div>
      <div className='font-normal mt-2 max-w-80'>{description}</div>
      <Link
        className='flex w-fit bg-white text-xs mt-4 py-1 px-6 mr-auto font-bold rounded-3xl text-buttonBlue'
        to={CONSULTANT}
        onClick={handleButton}
      >
        {buttonText ?? ADDITIONAL_INFO}
      </Link>
    </div>
  </div>
);

AdviceCard.Header = ({ children, title }: IAdCardHeaderProps) => (
  <>
    {title && (
    <div className='font-bold'>
      {title}
    </div>
    )}
    {children}
  </>
);

export default AdviceCard;
