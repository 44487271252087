import { useEffect } from 'react';

import CardCarousel from './Carousel/CardCarousel';
import MainCarouselCard from './Carousel/MainCarouselCard';
import CreditRating from './CreditRating/CreditRating';
import OptionsMenu from './OptionsMenu/OptionsMenu';
import LoanSuggestion from '../Suggestion/LoanSuggestion';
import Suggestion from '../Suggestion/Suggestion';
import LoanRequest from './LoanRequest';

import { PostOpportunityBody } from '../../interfaces';
import { bdiStore, userStore } from '../../store';
import { insightToCarousel, recommendationToCarousel } from '../../utils/carouselMapper';

import handHoldingPen from '../../assets/images/handHoldingPen.png';

import { INSIGHT_DEMO, PERSONAL_INSIGHTS, PERSONAL_RECOMMENDATIONS } from '../../constants';

import '../../styles/slickCarousel.css';

interface IMainContentProps {
  getMainPageData: () => Promise<any>
  handleContact: ({ description, product }: PostOpportunityBody) => Promise<void>
}

const MainContent = ({ getMainPageData, handleContact }:IMainContentProps) => {
  const {
    loanRequests,
    setLoanRequests,
    user,
  } = userStore();

  const {
    loans,
    recommendations,
    reset,
    setLoans,
    setRecommendations,
  } = bdiStore();

  useEffect(() => {
    getMainPageData().then((data) => {
      setLoanRequests(data?.loanRequests ?? []);
      setRecommendations(data?.recommendations ?? []);
      setLoans(data?.loans ?? []);
    });
    return () => {
      reset();
    };
  }, []);

  const SuggestionComponent = {
    offers: <Suggestion />,
    offer: <LoanSuggestion description={loans[0]?.productName} handleContent={() => handleContact({ product: loans[0]?.product })} image={handHoldingPen} />,
  };
  const SuggestionContent = () => {
    if (loans.length === 0) return null;

    if (loans.length === 1) return SuggestionComponent.offer;

    return SuggestionComponent.offers;
  };

  return (
    <>
      {loanRequests.length > 0 && loanRequests.map((loanRequest, index) => (
        <LoanRequest key={`loanRequest-${index}`} index={index} loanRequest={loanRequest} />
      ))}
      <div className='w-screen'>
        <CardCarousel
          CarouselCardComponent={MainCarouselCard}
          content={INSIGHT_DEMO.map(insightToCarousel)}
          title={PERSONAL_INSIGHTS}
        />
      </div>
      <div className='mt-[20px]'>
        <SuggestionContent />
      </div>
      {user?.bdiRating ? <CreditRating /> : null}
      {recommendations && recommendations?.length > 0 && (
        <div className='w-screen'>
          <CardCarousel
            CarouselCardComponent={MainCarouselCard}
            content={recommendations.map(recommendationToCarousel)}
            title={PERSONAL_RECOMMENDATIONS}
          />
        </div>
      )}
      <OptionsMenu />
    </>
  );
};

export default MainContent;
