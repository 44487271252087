import { Link } from 'react-router-dom';

import ContactUsVia from './Generic/ContactUsVia';

import { BACK_HOME_PAGE, HOME_PATH, NO_DATA_CONTENT } from '../constants';

const EmptyData = () => (
  <div className='flex flex-col h-full mx-auto gap-5 p-9 text-center'>
    <p className='mb-4'>
      {NO_DATA_CONTENT}
    </p>
    <ContactUsVia />
    <Link
      className='flex justify-center gap-x-1 w-full py-4 rounded-3xl bg-buttonBlue text-white font-bold mt-auto'
      to={HOME_PATH}
    >
      {BACK_HOME_PAGE}
    </Link>
  </div>
);

export default EmptyData;
