import { HTMLInputTypeAttribute, InputHTMLAttributes, SelectHTMLAttributes } from 'react';
import { FieldValues, RegisterOptions } from 'react-hook-form';

export type DocType = {
    key: string
    value: string
}

export type User = {
    userId: string
    firstname: string | null
    lastname: string | null
    fullName: string | null
    initials: string | null
    birthDate: Date | null
    city: string | null
    status: number
    partnerID: string | null
    email: string | null
    phoneNum: string
    secondaryPhoneNum: string | null
    employmentStatus: number
    seniority: number
    paycheckDay: number
    netMonthlyIncome: number
    netIncomeWithPartner: number
    hasProperty: boolean
    hasMortgage: boolean
    hasCar: boolean
    carProductionYear: number | null
    hasCarLien: boolean | null
    bdiRating: number | null,
    useMobileApplication: boolean;
}

export type Doc = {
    id: string
    documentType: string
    modifiedOn: Date
    name: string
    url: string
}

export enum LoanStatus {
    PENDING= 'בטיפול',
    APPROVED='אושר',
    REJECTED='לא אושר',
    DONE='הסתיים'
}

type LoanRequestStatusType<T> =
    T extends LoanStatus.PENDING ? { status: 0;
    reason: string | null;
    message: string | null;
} :
    T extends LoanStatus.APPROVED ? { status: 1 } :
    T extends LoanStatus.REJECTED ? { status: 2 } :
    never

export type LoanRequest<T> = {
    createdOn: string;
    modifiedOn: string;
    productName: string;
    statusName: string;
} & LoanRequestStatusType<T>

export enum FormInputs {
    select = 'select',
    input = 'input',
    date = 'date',
}

export type PersonalDetailInput<T> = {
    id: keyof User
    placeholder: string
} & (
  T extends FormInputs.select ? { mode: FormInputs.select, options?: { key: string, value: string }[] } & SelectHTMLAttributes<HTMLSelectElement> :
  T extends FormInputs.input ? { mode: FormInputs.input, type?: Omit<HTMLInputTypeAttribute, 'date' | 'select'>,
      rules ?: Omit<RegisterOptions<FieldValues, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>} & InputHTMLAttributes<HTMLInputElement> :
  T extends FormInputs.date ? { mode: FormInputs.date, type: 'date' } & InputHTMLAttributes<HTMLInputElement> :
    never
  )
