import { LoanLabels } from '../../interfaces';

export const LOAN_OFFER_TITLE = 'ההלוואה שלך';

export const MAIN_DETAILS_TITLE = 'הלוואה כללית מבנק';

export const LOAN_UPGRADE = {
  title: 'יש לי הצעה שיכולה לשדרג את ההלוואה שלך ולחסוך לך כסף!',
  description: 'לאחר בדיקה שעשיתי, מצאתי שאתה יכול לקבל תנאים טובים יותר להלוואה זו, לחץ כאן לעוד פרטים',
};

export const MAIN_DETAILS_LABELS: LoanLabels[] = [
  {
    name: 'סכום ההלוואה',
    keyValue: 'quantity',
    isMoney: true,
  },
  {
    name: 'יתרת חוב',
    keyValue: 'debtBalance',
    isMoney: true,
  },
  {
    name: 'ריבית',
    keyValue: 'interest',
  },
];
export const LOAN_DETAILS_TITLE = 'פרטי הלוואה';
export const LOAN_DETAILS_LABELS: LoanLabels[] = [
  {
    name: 'מקור מימון',
    keyValue: 'fundingOrg',
  },
  {
    name: 'מטרת האשראי',
    keyValue: 'creditGoal',
  },
  {
    name: 'תאריך תחילת העסקה',
    keyValue: 'startDate',
  },
  {
    name: 'תאריך סיום מתוכנן',
    keyValue: 'endDate',
  },
  {
    name: 'תדירות תשלומים',
    keyValue: 'frequency',
  },
  {
    name: 'ריבית נומינלית',
    keyValue: 'nominalInterest',
  },
  {
    name: 'הערכת החזר כולל',
    keyValue: 'totalReturn',
    isMoney: true,
  },
];

export const ADDITIONAL_DETAILS_TITLE = 'פרטים נוספים';
export const ADDITIONAL_DETAILS_LABELS: LoanLabels[] = [
  {
    name: 'יתרה שלא שולמה במועד',
    keyValue: 'balanceNotPaidInTime',
  },
  {
    name: 'בטוחה בהלוואה',
    keyValue: 'securityLoan',
  },
  {
    name: 'סכום חוב שנמחל',
    keyValue: 'debtRelief',
  },
  {
    name: 'אינדיקטור לאי פרעון',
    keyValue: 'nonPaymentIndicator',
  },
];
