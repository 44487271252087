import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import Loading from '../components/Loading/Loading';
import LoginForm from '../components/LoginForm/LoginForm';

import useApiRequests from '../hooks/useApiRequests';
import useBlockNavigation from '../hooks/useBlockNavigation';
import { useDevelopmentSkipLogin } from '../hooks/useDevelopmentSkipLogin';
import useIsMobile from '../hooks/useIsMobile';
import { userStore } from '../store';

import { MAIN, NON_MOBILE } from '../constants';

const LoginPage = () => {
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEV_SKIP_AUTH === 'true') useDevelopmentSkipLogin();
  const { blockNavigation, setBlockNavigation } = userStore();
  const navigate = useNavigate();
  const { loading, refreshToken } = useApiRequests();

  const isMobile = useIsMobile();
  useBlockNavigation(blockNavigation);

  useEffect(() => {
    if (isMobile && !blockNavigation) {
      if (!sessionStorage.getItem('accessToken')) {
        refreshToken().then((data) => {
          if (data?.accessToken) {
            sessionStorage.setItem('accessToken', data.accessToken);
            navigate(MAIN);
          } else {
            setBlockNavigation(true);
          }
        });
      } else {
        navigate(MAIN);
      }
    }
  }, []);

  if (loading) return <Loading />;

  if (isMobile) {
    return (
      <div className='overflow-x-hidden h-[100vh]'>
        <LoginForm />
      </div>
    );
  }

  return <Navigate replace to={NON_MOBILE} />;
};

export default LoginPage;
