import { ICreditRatingProgressBar } from '../interfaces';

import { creditRatingMainText, NO_DATA } from '../constants';

export const getCreditRating = (value: number): ICreditRatingProgressBar => {
  const colorRanges = [
    {
      min: 300,
      max: 579,
      color: '#FF6D8B',
      text: creditRatingMainText.low,
    },
    {
      min: 580,
      max: 639,
      color: '#FFB36D',
      text: creditRatingMainText.medium,
    },
    {
      min: 640,
      max: 659,
      color: '#FEE883',
      text: creditRatingMainText.medium,
    },
    {
      min: 660,
      max: 719,
      color: '#A5EB6E',
      text: creditRatingMainText.high,
    },
    {
      min: 720,
      max: 800,
      color: '#6FEBAA',
      text: creditRatingMainText.high,
    },
  ];

  const element = colorRanges.find(
    color => color.min <= value && value <= color.max,
  );

  const elementIndex = element ? colorRanges.indexOf(element) : 0;

  const { max, min } = (element!);
  const angle = ((((value - min) / (max - min)) * 0.2) + (elementIndex > 0 ? (elementIndex * 0.2) : 0)) * 180;

  return { angle, strokeColor: element?.color ?? 'black', text: element?.text ?? NO_DATA };
};
