import { ChangeEvent, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Input from '../Form/Input/Input';
import Loading from '../Loading/Loading';
import Timer from './Timer';

import { RateLimitExceeded } from '../../interfaces';
import { loginStore } from '../../store/loginStore';
import { mimoonPopupOptions } from '../../utils/popup';

import { BLOCK_TIME, loginPageText, NOT_FOUND_STATUS, PHONE_LENGTH, phoneRegex, POPUP_ERRORS, TOO_MANY_REQUESTS } from '../../constants';

interface IPhoneInput {
  loading: boolean
  error: AxiosError<unknown, any> | null
}

const PhoneInput = ({ error, loading }: IPhoneInput) => {
  const navigate = useNavigate();
  const {
    blockUser,
    formData,
    inputValidated,
    reset,
    setInputValidated,
    setPhoneNumber,
  } = loginStore();

  const { phoneNumber } = formData;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, '');
    setPhoneNumber(numericValue);
    setInputValidated(null);

    if (value.length === PHONE_LENGTH) {
      const phoneValidated = phoneRegex.test(value);
      setInputValidated(phoneValidated);
    }
  };

  const handleBlur = () => {
    setInputValidated(!(phoneNumber.length > 0 && !(phoneNumber.length === PHONE_LENGTH && phoneRegex.test(phoneNumber))));
  };

  useEffect(() => {
    if (error?.response?.status === NOT_FOUND_STATUS) {
      reset();
      navigate('/create-account');
    }

    if (error?.response?.status === TOO_MANY_REQUESTS) {
      blockUser();
      withReactContent(Swal).fire(mimoonPopupOptions({
        html: (
          <div>
            {POPUP_ERRORS.tooManyRequests}
            <Timer timerFinished seconds={(error?.response.data as RateLimitExceeded)?.timeRemaining ?? BLOCK_TIME} setTimerFinished={() => Swal.close()} />
          </div>),
        customClass: {
          container: 'whitespace-pre-line text-right',
        },
      }));
    }
  }, [error]);

  return (
    <>
      {loading && <Loading />}
      <div className='mx-[28px] my-[64px]'>
        <div className='font-bold text-base'>{loginPageText.phone.title}</div>
        <div className='text-sm mt-[15px]'>{loginPageText.phone.subtitle}</div>
        <div className='mt-[49px]'>
          <Input
            enterKeyHint='done'
            errorMessage={error?.response?.status !== TOO_MANY_REQUESTS && inputValidated === false ? loginPageText.phone.invalid_content : undefined}
            id='phone-login'
            maxLength={10}
            placeholder={loginPageText.phone.input_title}
            type='tel'
            value={phoneNumber}
            onBlur={handleBlur}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </>
  );
};

export default PhoneInput;
