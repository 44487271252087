import { FunctionComponent, SVGProps } from 'react';
import { Link } from 'react-router-dom';

import { GenericLeftArrow } from '../../../assets/genericLeftArrow';

import { COLORS } from '../../../constants';

export interface IOptionsMenuSectionProps {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>,
  text: string,
  navigationPath: string
}
const OptionsMenuSection = (props: IOptionsMenuSectionProps) => {
  const { Icon, navigationPath, text } = props;

  return (
    <Link className='border-t-[#D8D8D8] border-t-[0.5px] px-[16px] py-[20px] flex w-full justify-between items-center' to={navigationPath}>
      <div className='flex items-center'>
        <Icon />
        <p className='text-buttonBlue font-semibold text-small mr-3'>{text}</p>
      </div>
      <GenericLeftArrow strokeColor={COLORS.LIGHT_GRAY} />
    </Link>
  );
};
export default OptionsMenuSection;
