import { create } from 'zustand';

import { INotification } from '../interfaces';

interface IUILogicStore {
  isApp: boolean
  menuOpen: boolean
  toggleMenuOpen: () => void
  notifications: INotification[];
}

export const uiLogicStore = create<IUILogicStore>((set) => ({
  isApp: !!(window as any).Android,
  menuOpen: false,
  notifications: [],
  toggleMenuOpen: () => set((state) => ({ ...state, menuOpen: !state.menuOpen })),
}));
