import { FunctionComponent, SVGProps } from 'react';

import { LOANS_TYPES_ICONS } from '../constants';

export enum CreditRatingDetailColor {
    GREEN = 1,
    RED,
    YELLOW
}
export interface INotification {
    seen: boolean,
    title: string,
    content: string,
    ref?: string,
}

export interface ICreditRatingProgressBar {
    angle: number
    strokeColor: string;
    text: string
}

export interface ICreditRatingDetail {
    title: string;
    description: string;
    color: CreditRatingDetailColor
    consult?: boolean;
}

export interface ICreditRatingCategory {
    id: string;
    data: ICreditRatingDetail[];
}

export interface Loan {
    id: string
    fundingOrg: string,
    fundingIcon: string,
    quantity: number,
    startDate: string,
    endDate: string,
}

export interface LoanDetails extends Loan {
    requestDate: string,
    monthlyReturn: number,
    interest: string,
    creditGoal: string,
    numPayments: number,
    frequency: string,
    nominalInterest: number,
    debtBalance: number,
    totalReturn: number,
    originalCreditAmount: number,
    balanceNotPaidInTime: number,
    securityLoan: number,
    debtRelief: number,
    unusedCredit: number,
    totalArrears: number,
    daysArrears: number,
    nonPaymentIndicator: number
}
export type LoanKeys = keyof Loan
export type LoanDetailsKeys = keyof LoanDetails

export interface LoanLabels {
    name: string
    keyValue: LoanDetailsKeys | LoanKeys
    isMoney?: boolean
}

export interface Product {
    key: string,
    value: string
}

export interface RateLimitExceeded {
    message: string
    timeRemaining: number
}

export interface InfoItem {
    id: number,
    subject: string
    description: string
    isFaq: boolean
}

export interface ReportEssence {
    arrearsInPayments: CreditRatingDetailColor,
    arrearsInPaymentsDesc: null | string,
    nonPaymentOfLoan: CreditRatingDetailColor,
    nonPaymentOfLoanDesc: null | string,
    significanceForNonPaymentIndication: CreditRatingDetailColor,
    significanceForNonPaymentIndicationDesc: null | string,
    significanceForNonPaymentExclusion: CreditRatingDetailColor,
    significanceForNonPaymentExclusionDesc: null | string,
    checkAlertLetters: CreditRatingDetailColor,
    checkAlertLettersDesc: null | string,
    returnOfChecks: CreditRatingDetailColor,
    returnOfChecksDesc: null | string,
    disregardedStandingOrders: CreditRatingDetailColor,
    disregardedStandingOrdersDesc: null | string,
    executionLimitedCharged: CreditRatingDetailColor,
    executionLimitedChargedDesc: null | string,
    executionTransactionsInProgress: CreditRatingDetailColor,
    executionTransactionsInProgressDesc: null | string,
    execution: CreditRatingDetailColor,
    executionDesc: null | string,
    insolvency: CreditRatingDetailColor,
    insolvencyDesc: null | string,
    limitedAccountsIsraelBank: CreditRatingDetailColor,
    limitedAccountsIsraelBankDesc: null | string,
    legalProceedings: CreditRatingDetailColor,
    legalProceedingsDesc: null | string,
    restructuringOfProblematicDebt: CreditRatingDetailColor,
    restructuringOfProblematicDebtDesc: null | string
}

export interface BDIReport {
    bdiScore: number,
    modifiedOn: string,
    reportEssence: ReportEssence
    ratingReasons: string[]
}

export interface ReportEssenceByCategory {
    [key: string]: {
        icon: FunctionComponent<SVGProps<SVGSVGElement>>,
        title: string,
        order: number,
        content: {
            title: string,
            color: CreditRatingDetailColor,
            description: string
        }[]
    }
}

export interface Recommendations {
    title: string | null,
    createDate: string
    link: string | null
    recomandationSummery: string
    recomendation: string | null
}

export interface Insight {
    insight: string,
    recommendation: string
    action: string
}

export interface LoanType {
    product: string
    productName: keyof typeof LOANS_TYPES_ICONS
}

export interface IPrivacyPolicy {
    content: string,
    subContent?: IPrivacyPolicy[]
}

export interface PostOpportunityBody {
    product: Product['key'],
    description?: string,
}
