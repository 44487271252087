import { ReactElement } from 'react';
import { ToastContainer } from 'react-toastify';

import Menu from '../Menu/Menu';
import Navbar from '../Navbar/Navbar';

import 'react-toastify/dist/ReactToastify.css';

interface ILayout {
  children: ReactElement
}

const Layout = ({ children }: ILayout) => (
  <div className='flex flex-col layout h-full overflow-x-hidden'>
    <Navbar />
    {children}
    <Menu />
    <ToastContainer />
  </div>
);

export default Layout;
