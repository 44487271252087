import { ReactComponent as ChevronDownEllipse } from '../../assets/icons/chevronDownEllipse.svg';

interface IValueSection {
    description: string
}

const ValueSection = ({ description }: IValueSection) => (
  <div className='group'>
    <p className='bg-[#B9E7F466] rounded-[10px] py-5 px-4'>{description}</p>
    <ChevronDownEllipse className='group-last:hidden mx-auto my-6' />
  </div>
);

export default ValueSection;
