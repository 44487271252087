import assistant from '../../assets/images/assistant.png';

import { LAST_SLIDE_HEADER, WALKTHROUGH_HEADER } from '../../constants';

interface IWalkthroughContent {
    title: string,
    description: string
    lastSlider: boolean
}
const SlideContent = ({ description, lastSlider, title }:IWalkthroughContent) => (
  <div className='direction-rtl h-full'>
    <div className='flex my-10 mx-5 items-center'>
      <img
        alt='assistant'
        className='rounded-full border-[2px] border-white w-[60px] h-[60px] ml-[12px]'
        src={assistant}
      />
      <span className='font-bold text-lg'>
        {lastSlider ? LAST_SLIDE_HEADER : WALKTHROUGH_HEADER}
      </span>
    </div>
    <div
      className='bg-gradient-to-b from-[#00C0FF] via-[#4775EA] from-35% via to-[#5558FF] p-[1px] mx-9 rounded-xl shadow-walkthroughSlide'
    >
      <div className='flex flex-col gap-y-2 text-center py-7 px-6 bg-[#f5faff] rounded-[11px]'>
        <span className='font-bold text-lg'>{title}</span>
        <p className='text-sm leading-[22px]'>{description}</p>
      </div>
    </div>
  </div>
);

export default SlideContent;
