import { NO_DOCS } from '../../../constants';

const EmptyState = () => {
  const { content, icon } = NO_DOCS;
  return (
    <div className='flex flex-col h-full text-center'>
      <div className='my-auto flex flex-col h-full'>
        <img alt='empty box' className='mx-auto my-10 w-[88px]' src={icon} />
        <p className='mx-auto max-w-72 whitespace-pre-line'>
          {content}
        </p>
      </div>
    </div>
  );
};

export default EmptyState;
