import { useNavigate, useNavigationType } from 'react-router-dom';

import { ReactComponent as RightArrowWhite } from '../../assets/icons/rightArrowWhite.svg';

import { BACK, MAIN } from '../../constants';

const Back = () => {
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const handleNavigateBack = () => {
    if (navigationType === 'POP') return navigate(MAIN);

    return navigate(-1);
  };

  return (
    <div className='text-white mt-4'>
      <button
        className='flex items-center mr-4'
        type='button'
        onClick={handleNavigateBack}
      >
        <RightArrowWhite
          className='w-[24px] h-[24px] z-10'
          role='presentation'
        />
        <span className='font-bold text-sm'>{BACK}</span>
      </button>
    </div>
  );
};

export default Back;
