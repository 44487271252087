import { Link } from 'react-router-dom';

import CreditRatingHeader from './CreditRatingHeader';
import CreditRatingProgressBar from './CreditRatingProgressBar';

import { GenericLeftArrow } from '../../../assets/genericLeftArrow';

import { COLORS, creditRatingMainText } from '../../../constants';

const CreditRating = () => (
  <div className='relative w-full rounded-[21px] pt-[18px] flex flex-col bg-white'>
    <div className='pr-[17px]'>
      <CreditRatingHeader />
    </div>
    <CreditRatingProgressBar />
    <Link className='mt-3 mx-5 flex items-center justify-center border-t-[#D8D8D8] border-t-[0.5px] py-[19px] text-center text-small' to='/credit-rating'>
      <p className='text-buttonBlue font-bold ml-2'>{creditRatingMainText.all_info_credit_rating}</p>
      <GenericLeftArrow strokeColor={COLORS.DEEP_SKY_BLUE} />
    </Link>
  </div>
);

export default CreditRating;
