import { CreditRatingDetailColor } from '../interfaces';

import { SEVERITY } from '../constants';

export const useCreditRatingData = (color: number) => {
  switch (color) {
    case CreditRatingDetailColor.GREEN:
      return ({
        severity: SEVERITY.positive,
        color: '#66DFA5',
        rangeX: 45,
      });
    case CreditRatingDetailColor.RED:
      return ({
        severity: SEVERITY.negative,
        color: '#FF8BA2',
        rangeX: 10,
      });
    default:
      return {
        severity: SEVERITY.neutral,
        color: '#FFCA99',
        rangeX: 30,
      };
  }
};
