import { Link } from 'react-router-dom';

import CardDetails from './CardDetails';

import { GenericLeftArrow } from '../../assets/genericLeftArrow';
import { Loan } from '../../interfaces';

import { ADDITIONAL_INFO, COLORS } from '../../constants';

interface ILoanOfferCard {
    loanOfferCard: Loan
}
const PreviousLoanListCard = ({ loanOfferCard }: ILoanOfferCard) => (
  <div className='text-center text-small border border-buttonBlue rounded-3xl relative py-6'>
    <CardDetails loanOfferCard={loanOfferCard} />
    <Link className='flex justify-center items-center pt-6 text-buttonBlue text-small font-bold' to={`/prev-loans/${loanOfferCard.id}`}>
      {ADDITIONAL_INFO}
      <span className='pr-2'>
        <GenericLeftArrow strokeColor={COLORS.DEEP_SKY_BLUE} />
      </span>
    </Link>
  </div>
);

export default PreviousLoanListCard;
