import { User } from '../../interfaces';

import emptyBox from '../../assets/images/emptyBox.png';

export const PERSONAL_PROFILE_TITLE = 'פרופיל אישי';

export const TABS = {
  DETAILS: 'הפרטים שלי',
  DOCUMENTS: 'המסמכים שלי',
} as const;

export const SECTION = {
  PERSONAL_DETAILS: {
    LABEL: 'פרטים אישיים',
    FORM_LABELS: {
      FIRST_NAME: 'שם פרטי',
      LAST_NAME: 'שם משפחה',
      BIRTH_DATE: 'תאריך לידה',
      CITY: 'עיר מגורים',
    },
  },
  FAMILY_SITUATION: {
    LABEL: 'פרטים אישיים - מצב משפחתי',
    FORM_LABELS: {
      STATUS: 'מצב משפחתי',
      PARTNER_ID_NUMBER: 'תעודת זהות בן/בת הזוג',
    },
  },
  COMMUNICATION: {
    LABEL: 'פרטי התקשרות',
    FORM_LABELS: {
      EMAIL: 'אימייל',
      PHONE_NUM: 'טלפון נייד',
      SECONDARY_PHONE_NUM: 'מספר שני',
    },
  },
  EMPLOYMENT: {
    LABEL: 'פרטים תעסוקתיים',
    FORM_LABELS: {
      EMPLOYMENT_STATUS: 'מצב תעסוקתי',
      SENIORITY: 'ותק',
      PAYCHECK_DAY: 'יום בחודש שבו נכנסת המשכורת',
      NET_MONTHLY_INCOME: 'הכנסה חודשית (נטו)',
      NET_INCOME_WITH_PARTNER: 'הכנסה חודשית שלך ושל בן/הזוג ביחד (נטו)',
    },
  },
  ADDITIONAL_DETAILS: {
    LABEL: 'פרטים נוספים',
    FORM_LABELS: {
      HAS_PROPERTY: 'האם יש נכס על שמך?',
      HAS_MORTGAGE: 'האם קיימת משכנתא?',
      HAS_CAR: 'האם יש בבעלותך רכב?',
      CAR_PRODUCTION_YEAR: 'מהי שנת הייצור של הרכב?',
      HAS_CAR_LIEN: 'האם יש שעבוד על הרכב?',
    },
  },
} as const;

export const UPLOAD_BUTTON = 'העלאת מסמך חדש';

export const FILE_DESCRIPTION = {
  fileName: 'שם קובץ',
  fileDate: 'תאריך העלאה',
};

export const UPDATE_BUTTON_TEXT = 'שמירה';

export const ERRORS_MESSAGES = {
  textShort: 'הטקסט קצר מהנדרש, אנא תאריך את הטקסט',
  textLong: 'הטקסט ארוך מהנדרש, אנא קצר את הטקסט',
  valueShort: 'המספר שהזנת נמוך, אנא נסה שוב',
  valueLong: 'המספר שהזנת גבוה מהנדרש',
  email: 'האימייל שהכנסת לא תקין, אנא נסה שוב',
  phone: 'הטלפון שהכנסת לא תקין, אנא נסה שוב',
  taz: 'הת.ז שהזנת לא תקין אנא נסה שוב',
  hebrewLetters: 'הטקסט שהזנת לא תקין כתוב בעברית בלבד',
  required: 'הטקסט לא יכול להיות ריק',
  netMonthlyIncomeMin: 'השכר נטו לא יכול להיות נמוך מ1000₪',
  carProductionYearMin: 'רכב לא יכול להיות לפני שנת 2000',
  carProductionYearMax: 'רכב לא יכול להיות אחרי השנה הנוכחית',

} as const;

export const UPDATE_DETAILS_MESSAGE = {
  SUCCESS: 'עודכן בהצלחה',
  ERROR: 'השדות לא עודכנו',
};

export const NO_DOCS = {
  icon: emptyBox,
  content: 'אין לך מסמכים להצגה כרגע.\n חזור לכאן מאוחר יותר או צור מסמכים חדשים במערכת',
};

export const MAX_BIRTHDAY_YEAR_SUBTRACTION = 18;
export const MIN_BIRTHDAY_YEAR_SUBTRACTION = 100;

export const DEPENDANT_ITEM: keyof User = 'hasCar';
export const DEPENDANT_LIST:(keyof User)[] = ['hasCarLien', 'carProductionYear'];
