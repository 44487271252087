import { Link } from 'react-router-dom';

import { LoanType as ILoanType } from '../../interfaces';

import loanConsolidation from '../../assets/images/loanConsolidation.png';

import { APPLY_FOR_LOAN, CONSULTANT, LOANS_TYPES_ICONS, TYPE_DESCRIPTION } from '../../constants';

export interface ILoanTypeProps {
  handleContact: () => Promise<void>
}

const LoanType = ({
  handleContact, productName,
}: ILoanType & ILoanTypeProps) => {
  const loanImage = LOANS_TYPES_ICONS[productName] ?? loanConsolidation;

  return (
    <div
      className='flex flex-col overflow-hidden text-center rounded-3xl pb-4
              shadow-card border-2 border-solid border-[#F2F2F2]'
    >
      <img alt='loan-offer' className='w-full max-h-24 object-cover' src={loanImage} />
      <div>
        <h1 className='text-[#212F61] text-lg font-bold py-3'>{productName}</h1>
        <p className='text-gray-400 text-xs px-3'>{TYPE_DESCRIPTION}</p>
      </div>
      <Link
        className='rounded-[29px] py-2 px-20 w-fit mx-auto mt-5
              text-buttonBlue border border-buttonBlue font-bold text-sm'
        to={CONSULTANT}
        onClick={handleContact}
      >
        {APPLY_FOR_LOAN}
      </Link>
    </div>
  );
};

export default LoanType;
