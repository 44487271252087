import { NO_TYPES } from '../../constants';

const EmptyState = () => {
  const { buttonText, content, icon } = NO_TYPES;
  return (
    <div className='flex flex-col h-full text-center'>
      <div className='my-auto flex flex-col h-full'>
        <img alt='empty box' className='mx-auto my-10 w-[88px]' src={icon} />
        <p className='mx-auto max-w-72'>
          {content}
        </p>
      </div>
      <button className='bg-buttonBlue text-white rounded-3xl py-3 px-16 my-10' type='button'>{buttonText}</button>
    </div>
  );
};

export default EmptyState;
