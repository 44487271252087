import Loading from '../components/Loading/Loading';
import Header from '../components/Main/Header';
import MainContent from '../components/Main/MainContent';
import NoBdi from '../components/Main/NoBdi/NoBdi';

import useApiRequests from '../hooks/useApiRequests';
import { userStore } from '../store';

const MainPage = () => {
  const {
    hasBdi,
  } = userStore();

  const { getMainPageData, getNoBdi, loading, postOpportunity } = useApiRequests();

  return (
    <div className='overscroll-contain overflow-x-hidden'>
      {loading && <Loading />}
      <div className='flex flex-col gap-y-8 p-[15px]'>
        <Header />
        {
          hasBdi
            ? (
              <MainContent
                getMainPageData={getMainPageData}
                handleContact={postOpportunity}
              />
            )
            : (
              <NoBdi
                getNoBdi={getNoBdi}
                handleContact={postOpportunity}
              />
            )
        }
      </div>
    </div>
  );
};
export default MainPage;
