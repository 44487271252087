import { useEffect, useRef } from 'react';
import Chart, { ArcElement, Plugin } from 'chart.js/auto';

import { getCreditRating } from '../../../utils/getCreditRating';

interface DoughnutChartProps {
  bdi: number;
}

const convertAngle = (angle: number) => {
  const anglePercentage = (angle / 180) * 100;
  return (((anglePercentage / 100) * 180) - 90) * (Math.PI / 180);
};

const DoughnutChart = ({ bdi }: DoughnutChartProps) => {
  const { angle, strokeColor, text } = getCreditRating(bdi);
  const chartContainer = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef <Chart<'doughnut', number[], string> | null>(null);

  const doughnutPointer: Plugin<'doughnut'> = {
    id: 'doughnutPointer',
    afterDatasetsDraw(chart: Chart<'doughnut'>) {
      const arc = chart.getDatasetMeta(0).data[0] as ArcElement; // Cast to ArcElement
      const { ctx } = chart;
      const centerX = arc.x;
      const centerY = arc.y;
      const { innerRadius, outerRadius } = arc;

      const pointerThickness = outerRadius - innerRadius;

      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(convertAngle(angle));
      ctx.beginPath();

      ctx.fillStyle = 'white';
      ctx.arc(centerX, centerY, 3, 0, Math.PI, false);
      ctx.roundRect(-2, 0 - outerRadius - pointerThickness / 5, pointerThickness * 1.25, pointerThickness * 1.25, 15);
      ctx.fill();

      ctx.strokeStyle = strokeColor;
      ctx.lineWidth = 3;
      ctx.stroke();

      ctx.restore();
    },
  };

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    if (chartContainer.current) {
      const ctx = chartContainer.current.getContext('2d');
      if (ctx) {
        chartInstance.current = new Chart<'doughnut', number[], string>(ctx, {
          type: 'doughnut',
          data: {
            datasets: [{
              data: [20, 20, 20, 20, 20],
              backgroundColor: [
                '#FF6D8B',
                '#FFB36D',
                '#FEE883',
                '#A5EB6E',
                '#6FEBAA',
              ],
              borderWidth: 4,
              borderRadius: 8,
            }],
          },
          options: {
            cutout: 54,
            aspectRatio: 2,
            rotation: -90,
            circumference: 180,
            plugins: {
              tooltip: {
                enabled: false,
              },
            },
            events: [],
            responsive: true,
            maintainAspectRatio: false,
          },
          plugins: [doughnutPointer],
        });
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);
  return (
    <div className='relative text-center'>
      <canvas ref={chartContainer} className='w-[245px] h-[133px]' />
      <div className='credit-rating-level'>
        <span className='text-xl'>{bdi}</span>
        <p className='text-xl'>{text}</p>
      </div>
    </div>
  );
};
export default DoughnutChart;
