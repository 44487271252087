import { Tooltip } from 'react-tooltip';

import { ReactComponent as InfoPlain } from '../../../assets/icons/infoPlain.svg';

import { CREDIT_RATING_TOOLTIP, creditRatingMainText, creditRatingText } from '../../../constants';

const CreditRatingHeader = () => (
  <>
    <div className='flex items-center gap-2'>
      <h3 className='font-bold'>{creditRatingMainText.your_credit_rating}</h3>
      <InfoPlain className='w-[22px] h-[22px]' data-tooltip-id='credit-rating-info' />
      <Tooltip
        disableStyleInjection
        className='z-40 bg-white shadow-tooltip py-3 px-6 rounded-lg max-w-56'
        id='credit-rating-info'
        place='bottom'
        variant='light'
      >
        {CREDIT_RATING_TOOLTIP}
      </Tooltip>
    </div>
    <span className='text-lightGrey text-sm'>{creditRatingText.distribution}</span>
  </>
);

export default CreditRatingHeader;
