import { useEffect, useState } from 'react';

import AdviceCard from '../../AdviceCard';
import LoanSuggestion from '../../Suggestion/LoanSuggestion';
import CardCarousel from '../Carousel/CardCarousel';
import NoBdiCard from '../Carousel/NoBdiCard';
import LoanRequest from '../LoanRequest';

import { PostOpportunityBody } from '../../../interfaces';
import { userStore } from '../../../store';

import { ReactComponent as like } from '../../../assets/icons/circle-thumb-like.svg';
import handHoldingPen from '../../../assets/images/handHoldingPen.png';

import { NO_BDI } from '../../../constants';

interface INoBdiProps {
  getNoBdi: () => Promise<any>
  handleContact: ({ description, product }: PostOpportunityBody) => Promise<void>
}

const NoBdi = ({ getNoBdi, handleContact }: INoBdiProps) => {
  const [noBdiArticles, setNoBdiArticles] = useState([]);
  const { loanRequests, setLoanRequests } = userStore();

  useEffect(() => {
    getNoBdi().then(data => {
      setNoBdiArticles(data?.noBdiArticles ?? []);
      setLoanRequests(data?.loanRequests ?? []);
    });
  }, []);

  return (
    <>
      {loanRequests.length > 0 && loanRequests.map((loanRequest, index) => (
        <LoanRequest key={`loanRequest-${index}`} index={index} loanRequest={loanRequest} />
      ))}
      <AdviceCard Img={like} buttonText={NO_BDI.CONTACT_US.button} description={NO_BDI.CONTACT_US.description}>
        <AdviceCard.Header title={NO_BDI.CONTACT_US.title} />
      </AdviceCard>
      {noBdiArticles.length > 0 && (
        <div className='w-screen mb-8'>
          <CardCarousel
            CarouselCardComponent={NoBdiCard}
            content={noBdiArticles.slice(0, 4)}
            title={NO_BDI.CARD_TITLE}
          />
        </div>
      )}
      <LoanSuggestion description={NO_BDI.LOAN_SUGGESTION.description} handleContent={() => handleContact({ product: NO_BDI.LOAN_SUGGESTION.productId })} image={handHoldingPen} />
    </>
  );
};

export default NoBdi;
