import React, { SelectHTMLAttributes, useEffect, useState } from 'react';

import LoadingSpinner from '../../Loading/LoadingSpinner';

import useApiRequests from '../../../hooks/useApiRequests';
import { Product } from '../../../interfaces';

import { ReactComponent as ChevronDown } from '../../../assets/icons/selectChevronDown.svg';

import { SERVICE_SELECT_PLACEHOLDER } from '../../../constants';

interface IRequestSelect extends SelectHTMLAttributes<HTMLSelectElement>{
    service: string,
}
const RequestSelect = ({ onChange, service }:IRequestSelect) => {
  const [products, setProducts] = useState<Product[]>([]);
  const { getProducts, loading } = useApiRequests();

  useEffect(() => {
    getProducts().then(data => setProducts(data ?? []));
  }, []);

  return (
    <div className='relative mt-4'>
      <select
        className={`
            border w-full border-lightGrey border-solid focus:outline-buttonBlue p-3 bg-white
            rounded-lg text-sm appearance-none ${service === '' ? 'text-lightGrey' : ''}
            ${loading ? 'cursor-wait' : ''}`}
        disabled={loading}
        name='service-select'
        onChange={onChange}
      >
        <option value=''>{SERVICE_SELECT_PLACEHOLDER}</option>
        {products.map(subject => (
          <option key={subject.value} value={subject.value}>{subject.key}</option>
        ))}
      </select>
      <div className='absolute left-3 top-1/2 -translate-y-1/2'>
        {loading ? <LoadingSpinner /> : <ChevronDown />}
      </div>
    </div>
  );
};

export default RequestSelect;
