import { AxiosError } from 'axios';
import { create } from 'zustand';

interface ILoginStore {
  loginStep: 'phone' | 'otp'
  formData: {
    phoneNumber: string
    OTPCode: string
  }
  setPhoneNumber: (phoneNumber: string) => void
  setOTPCode: (OTPCode: string) => void
  checkedPrivacyPolicy: boolean
  togglePrivacyPolicy: () => void
  inputValidated: boolean | null
  setInputValidated: (inputValidated: boolean | null) => void
  showLetsStart: boolean
  setShowLetsStart: (showLetsStart: boolean) => void

  reset: () => void
  phoneVerified: () => void
  blockUser: () => void
  newOtpCode: () => void

  serverError: AxiosError | null
  setServerError: (serverError: AxiosError | null) => void

  canResend: boolean,
  setCanResend: (canResend: boolean) => void
}

const defaultStoreData = {
  showLetsStart: false,
  inputValidated: null,
  formData: {
    phoneNumber: '',
    OTPCode: '',
  },
  canResend: false,
  serverError: null,
};

export const loginStore = create<ILoginStore>((set) => ({
  loginStep: 'phone',
  checkedPrivacyPolicy: false,
  ...defaultStoreData,
  reset: () => set((state) => ({
    ...state, ...defaultStoreData, loginStep: 'phone', checkedPrivacyPolicy: false,
  })),
  setShowLetsStart: (showLetsStart) => set((state) => ({
    ...state, showLetsStart,
  })),
  setInputValidated: (inputValidated) => set((state) => ({
    ...state, inputValidated,
  })),
  togglePrivacyPolicy: () => set((state) => ({
    ...state, checkedPrivacyPolicy: !state.checkedPrivacyPolicy,
  })),
  phoneVerified: () => set((state) => ({
    ...state,
    loginStep: 'otp',
    inputValidated: false,
  })),
  setPhoneNumber: (phoneNumber) => set((state) => ({
    ...state, formData: { ...state.formData, phoneNumber },
  })),
  setOTPCode: (OTPCode) => set((state) => ({
    ...state, formData: { ...state.formData, OTPCode },
  })),
  blockUser: () => set((state) => ({
    ...state, ...defaultStoreData, inputValidated: false,
  })),
  newOtpCode: () => set((state) => ({
    ...state,
    ...defaultStoreData,
    formData: { ...state.formData, phoneNumber: state.formData.phoneNumber },
  })),
  setCanResend: (canResend) => set((state) => ({ ...state, canResend })),
  setServerError: (serverError: AxiosError | null) => set((state) => ({ ...state, serverError })),
}));
