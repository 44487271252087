import assistant from '../assets/images/assistant.png';

interface HeaderProps {
  title: string;
}

const ConsultHeader = ({ title }: HeaderProps) => (
  <div className='w-full flex px-[19px] mt-[25px] items-center'>
    <img alt='assistant' className='rounded-full border-[2px] border-white w-[80px] h-[80px] ml-[24px]' src={assistant} />
    <div>
      <p className='max-w-48 text-white font-bold whitespace-pre-line'>
        {title}
      </p>
    </div>
  </div>
);

export default ConsultHeader;
