interface GenericLinearRangeBarProps {
  rangeX: number
}
export const GenericLinearRangeBar = ({ rangeX }: GenericLinearRangeBarProps) => (
  <svg fill='none' height='12' viewBox='0 0 55 12' width='55' xmlns='http://www.w3.org/2000/svg'>
    <rect fill='url(#paint0_linear_1050_6510)' fillOpacity='0.7' height='8' rx='4' transform='matrix(-1 0 0 1 55 2)' width='55' />
    <rect fill='black' height='12' rx='1.5' transform={`matrix(-1 0 0 2 ${rangeX} 0)`} width='3' />
    <defs>
      <linearGradient gradientUnits='userSpaceOnUse' id='paint0_linear_1050_6510' x1='0' x2='55' y1='4' y2='4'>
        <stop stopColor='#11D186' />
        <stop offset='0.211207' stopColor='#72D060' />
        <stop offset='0.478448' stopColor='#FBCA25' />
        <stop offset='0.724138' stopColor='#FD9830' />
        <stop offset='1' stopColor='#FE5C42' />
      </linearGradient>
    </defs>
  </svg>
);
