import { Link } from 'react-router-dom';

import { CONSULTANT, LOAN_SUGGESTION_BUTTON_TEXT, LOAN_SUGGESTION_INTRODUCTION } from '../../constants';

type SuggestionProps = {
  image: string
  description: string
  handleContent: () => Promise<void>
}

const LoanSuggestion = ({
  description, handleContent, image,
}: SuggestionProps) => (
  <div className='flex items-center border border-white bg-blur-card-wrapper overflow-hidden text-white rounded-3xl bg-cover bg-no-repeat'>
    <img alt='loan' src={image} width={100} />
    <div className='w-full'>
      <span className='flex px-4 text-balance font-bold text-lg max-w-64'>
        {`${LOAN_SUGGESTION_INTRODUCTION} ${description}!`}
      </span>
      <Link
        className='flex float-left bg-white text-xs font-bold mt-4 py-2 px-8 mr-auto ml-5 rounded-3xl text-buttonBlue'
        to={CONSULTANT}
        type='button'
        onClick={handleContent}
      >
        {LOAN_SUGGESTION_BUTTON_TEXT}
      </Link>
    </div>
  </div>
);
export default LoanSuggestion;
