import { useEffect, useState } from 'react';

const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export interface ITimerProps {
    seconds: number,
    timerFinished: boolean
    setTimerFinished: (timerFinished:boolean) => void
}
const Timer = (props:ITimerProps) => {
  const { seconds, setTimerFinished, timerFinished } = props;
  const [countdown, setCountdown] = useState(seconds);

  useEffect(() => {
    if (!timerFinished) setCountdown(seconds);
  }, [timerFinished, seconds]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        setTimerFinished(true);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [countdown]);

  return <div>{formatTime(countdown)}</div>;
};

export default Timer;
