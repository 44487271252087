import { forwardRef, InputHTMLAttributes } from 'react';

import { displayedDate, formatDate } from '../../../utils/formatDate';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string
}

const DatePicker = forwardRef<HTMLInputElement, InputProps>(({
  id, placeholder, value, ...inputProps
}, ref) => (
  <div className='relative'>
    <div className='relative text-start'>
      <input
        className={`block text-right direction-rtl px-3 w-full min-h-14 py-4 rounded-lg border appearance-none 
                    focus:outline-none focus:ring-0 text-sm text-transparent bg-white disabled:bg-gray-100 border-[#D9D9D9] peer`}
        id={id}
        type='date'
        {...inputProps}
        ref={ref}
        value={formatDate(value as string) || ''}
      />
      <span className='absolute mx-[13px] origin-[0] pointer-events-none -translate-y-1/2 top-1/2 pl-4 text-sm'>
        {value ? displayedDate(value as string) : ''}
      </span>
      <label
        className={`absolute mx-[13px] px-1 transition-all duration-300 transform z-10 origin-[0] pointer-events-none bg-white
                  ${!value ? 'scale-100w right-0 text-sm text-[#A8A8A8] -translate-y-1/2 top-1/2 pl-4' : 'pl-1 -translate-y-4 top-2 -right-1 text-xs text-blue-600'}`}
        htmlFor={id}
      >
        {placeholder}
      </label>

    </div>
  </div>
));

DatePicker.displayName = 'DatePicker';

export default DatePicker;
