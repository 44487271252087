import React, { MutableRefObject, useState } from 'react';

import useClickOutside from '../../hooks/useClickOutside';

import { ReactComponent as ChevronDown } from '../../assets/icons/chevronDown.svg';
import { ReactComponent as ChevronUp } from '../../assets/icons/chevronUp.svg';

type collapse = 'primary' | 'info'

interface CollapseProps<T> {
    type: collapse
    label: string
    className?: string
    children: React.ReactNode
    customIcons?: {
        open: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
        close: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    }
    boundaryRef?: MutableRefObject<T | null>
    canToggle?: boolean
}

const Collapse = <T extends HTMLElement, >({
  boundaryRef, canToggle = true, children, className, customIcons = { open: ChevronDown, close: ChevronUp }, label, type,
}: CollapseProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useClickOutside(() => setTimeout(() => canToggle && setIsOpen(false), 0), boundaryRef) as MutableRefObject<HTMLDivElement | null>;

  const toggle = () => canToggle && setIsOpen(prevIsOpen => !prevIsOpen);

  return (
    <div ref={wrapperRef} className={`w-full border-b border-t-[#F2F2F2] hover:cursor-pointer last:border-none py-[30px] ${className || ''}`}>
      <button className='flex justify-between w-full focus:outline-none' type='button' onClick={toggle}>
        <span className={type === 'info' ? 'collapse-info' : 'collapse-primary'}>{label}</span>
        {
            isOpen
              ? <customIcons.close />
              : <customIcons.open />
        }

      </button>
      {isOpen && children }
    </div>
  );
};

export default Collapse;
