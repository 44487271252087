import CustomerServiceHeader from '../components/ContactUs/CustomerServiceHeader';
import Email from '../components/ContactUs/Email';
import Phone from '../components/ContactUs/Phone';
import Request from '../components/ContactUs/Request';
import PageLayout from '../components/Layout/PageLayout';

import useApiRequests from '../hooks/useApiRequests';

import { CONTACT_US_TITLE } from '../constants';

const ContactUs = () => {
  const { loading, postOpportunity } = useApiRequests();

  return (
    <PageLayout header={CONTACT_US_TITLE} loading={loading}>
      <div className='flex flex-col w-4/5 mx-auto pb-4 flex-grow'>
        <CustomerServiceHeader />
        <Phone />
        <Email />
        <Request postContactUsRequest={postOpportunity} />
      </div>
    </PageLayout>
  );
};

export default ContactUs;
