import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import ContactUsVia from '../components/Generic/ContactUsVia';

import useIsMobile from '../hooks/useIsMobile';
import { userStore } from '../store';

import noData from '../assets/images/noData.png';

import { ERROR_CONTACT_US, ERROR_CONTENT, ERROR_HEADER, LOGIN } from '../constants';

const NonMobileError = () => {
  const isMobile = useIsMobile();
  const { logOut, setBlockNavigation } = userStore();

  useEffect(() => {
    logOut();
  }, []);

  useEffect(() => {
    if (isMobile) setBlockNavigation(false);
  }, [isMobile]);

  if (!isMobile) {
    return (
      <div className='flex flex-col h-full gap-5 p-9 text-center items-center'>
        <span className='text-2xl font-bold'>{ERROR_HEADER}</span>
        <p className='text-sm'>{ERROR_CONTENT}</p>
        <img alt='error' className='h-2/3 object-contain' src={noData} />
        <div className='mt-auto'>
          <span className='block mb-3'>{ERROR_CONTACT_US}</span>
          <div className='flex flex-wrap w-full gap-4 justify-center'>
            <ContactUsVia />
          </div>
        </div>
      </div>
    );
  }

  return <Navigate replace to={LOGIN} />;
};

export default NonMobileError;
