import { docsUploadStore } from '../../store';

import { ReactComponent as Trash } from '../../assets/icons/trash.svg';

const DropzoneFiles = () => {
  const { files, removeFile } = docsUploadStore(state => state);

  return (
    <div className='flex flex-col mt-8 gap-3'>
      {files.map((file, index) => (
        <div
          key={`${file.name}-${index}`}
          className='flex justify-between items-center p-4 bg-[#4775ea0d] rounded-lg text-sm'
        >
          <span className='overflow-ellipsis whitespace-nowrap overflow-hidden'>{file.name}</span>
          <button className='mr-4' type='button' onClick={() => removeFile(file)}>
            <Trash />
          </button>
        </div>
      ))}
    </div>
  );
};

export default DropzoneFiles;
