import { CUSTOMER_SERVICE } from '../../constants';

const Email = () => (
  <div className='font-bold py-7 border-b'>
    <a
      aria-label='Email'
      className='flex flex-col gap-2'
      href={`mailto:${CUSTOMER_SERVICE.mail.mail_address}`}
      rel='noreferrer'
      target='_blank'
    >
      <span className='font-bold'>{CUSTOMER_SERVICE.mail.title}</span>
      <p className='text-small text-buttonBlue'>{CUSTOMER_SERVICE.mail.mail_address}</p>
    </a>
  </div>
);

export default Email;
