export const mainHeaderText = {
  hiGreeting: 'היי',
  subtitle: 'ריכזתי עבורך את כל העדכונים החשובים.',
  noBdiSubtitles: 'מוזמן/ת לעיין ולהיעזר בחומרי המידע שאספתי עבורך',
};

export const mainLoanRequestText = {
  status: 'סטטוס בקשה להלוואה',
  current_step: 'שלב נוכחי:',
  next_step: 'שלב הבא:',
  loan_type: 'סוג ההלוואה:',
} as const;

export const APPROVED_LOAN_REQUEST = {
  title: 'סטטוס בקשת הלוואה',
  loanLabel: 'סוג הלוואה:',
  footer: 'נא להיות זמין בשעות הקרובות לשיחה מול נציג והמשך תהליך קבלת ההלוואה',
};

export const PERSONAL_INSIGHTS = 'תובנות היועץ';

export const PERSONAL_RECOMMENDATIONS = 'המלצות אישיות';

export const GENERIC_SUGGESTION = 'לכל סוגי ההלוואות שמתאימות לך';
export const LOAN_SUGGESTION_BUTTON_TEXT = 'לקבלת הצעה';

export const LOAN_SUGGESTION_INTRODUCTION = 'מגיע לך';

export const INFO_SECTION = {
  myLoan: 'ההלוואות שלי',
  cardRating: 'הצעות לשיפור דירוג האשראי',
  loanOffer: 'ייעוץ לגבי הצעה להלוואה שקיבלת',
  searchDocuments: 'חיפוש מסמכים',
  questionAndAnswers: 'שאלות ותשובות',
  info: 'מי אנחנו',
};

export const NO_BDI = {
  CARD_TITLE: 'מידע שאולי יעניין אותך',
  LOAN_SUGGESTION: {
    description: 'מגוון פתרונות לקבלת הלוואה לכל מטרה',
    productId: '8a85313c-8a74-41c6-8bae-0493c147aef6',
  },
  CONTACT_US: {
    title: 'בואו נכיר יותר כדי לתת לך את הייעוץ הטוב ביותר',
    description: 'היועץ הדיגיטלי רוצה להכיר אותך יותר בכדי לבצע את ההתאמות עבורך',
    button: 'צרו קשר',
  },
};

export const MENU_OPTION_TITLE = 'פעולות ומידע';

export const INSIGHT_DEMO = [
  {
    insight: 'היועץ הדיגיטלי זיהה שיש ברשותך כרטיס אשראי בנקאי עם אחוז ניצול מסגרת של 110% בחודש האחרון דבר אשר גורם להורדת דירוג האשראי האופן ישיר',
    recommendation: 'היועץ הדיגיטלי ממליץ על כרטיס אשראי נוסף שיהיה ברשותך עם מסגרת אשראי נוספת לשימושך ולנהל את המסגרות אשראי עד ל70% שימוש מגובה המסגרת שנתנה ע״י חברת האשראי',
    action: 'לפנייה לבדיקת זכאות לכרטיס אשראי חדש עם מסגרת אשראי נוספת לחץ כאן או פנה ליועץ האישי שלך',
  },
  {
    insight: 'היועץ הדיגיטלי זיהה כי הנך לקוח משכנתא בבנק מזרחי טפחות',
    recommendation: 'לקוחות משכנתא במזרחי טפחות יכולים להנות מהלוואה לכל מטרה בהליך מזורז ומהיר עד 180 אלף שח באישור מיידי',
    action: 'לבדיקת היתכנות מול הבנקאי לחץ כאן או פנה ליועץ האישי שלך בלחיצה כאן למטה',
  },
  {
    insight: 'היועץ הדיגיטלי מעדכן כי ההלוואה האחרונה שנטלת היא הלוואה - ישראכרט. בריבית שנתית של 15%',
    recommendation: 'היועץ הדיגיטלי ממליץ לבצע בדיקה נוספת מדוע קיים הצורך ללקיחת הלוואה נוספת ובאלו ריביות ותנאים.',
    action: 'לשיחה עם היועץ האישי שלך על טווחי ריביות שמתאימים לך אישית לחץ כאן',
  },
];
