// TODO: add correct update date, value of credit rating, level of credit rating
import DoughnutChart from './DoughnutChart';

import { userStore } from '../../../store';

import '../../../styles/CreditRatingProgressBar.css';

const CreditRatingProgressBar = () => {
  const { user } = userStore();
  return (
    <div className='chartCard'>
      <div className='chartBox'>
        {user?.bdiRating != null && <DoughnutChart bdi={user?.bdiRating} />}
      </div>
    </div>
  );
};

export default CreditRatingProgressBar;
