import { ReactComponent as CustomerSupport } from '../../assets/icons/customerSupport.svg';
import { ReactComponent as GraphBarIncrease } from '../../assets/icons/graphBarIncrease.svg';
import { ReactComponent as Loan } from '../../assets/icons/loan.svg';
import { ReactComponent as ThumbLike } from '../../assets/icons/thumb-like.svg';

export const INFO_CENTER_TITLE = 'מרכז מידע';

export const FAQ_TITLE = 'שאלות ותשובות';

export const INFO_TITLE = 'מי אנחנו';

export const INFO_EMPTY_STATE = 'אופס… קרתה תקלה, אנא נסה/י שנית מאוחר יותר';

export const INFO_PROFILE = {
  title: 'איך ולמה נוסדנו',
  description: `מימון כל היא חברה מובילה, חדשנית וייחודית בתחום מציאת פתרונות אשראי
    , מימון ומשכנתאות מכל הסוגים ללקוחות פרטיים ועסקיים ועוד פתרונות אשראי רחבים בכל התחומים.`,
};

export const INFO_SECTIONS = [{
  Icon: Loan,
  description: `בין היתר אנחנו מתמחים באיתור פתרונות אשראי חוץ בנקאיים,
   הלוואות כנגד נכסים ממשיים ונכסים פיננסים, ייעוץ משכנתאות, ערבויות והלוואות בערבות מדינה.`,
},
{
  Icon: CustomerSupport,
  description: `השירות הייחודי שלנו ניתן על ידי יועצים ויועצות פיננסים מהטובים בתחום. השירות כולל היכרות מעמיקה עם המצב הפיננסי של הלקוחות,
     איתור נקודות התורפה במידה ויש כאלה, התאמת מקורות מימון רלוונטיים ויצירת תחרות ביניהם, כדי להעניק ללקוחות את התנאים האטרקטיביים ביותר.`,
},
{
  Icon: GraphBarIncrease,
  description: `מימון כל מצליחה להשיג פתרונות ללקוחות פרטיים ועסקיים במקומות שגופים אחרים נכשלו,
   בזכות המומחיות, הניסיון, החדשנות הדיגיטלית ויכולת עבודה מול כל הגופים בארץ.`,
},
{
  Icon: ThumbLike,
  description: 'אנחנו מתמחים בלהפוך את ה"לא" ל-"כן',
}];

export const INFO_VALUES_TITLE = 'הערכים שלנו';

export const VALUES_SECTIONS = [
  `מימון כל היא החברה היחידה הפועלת באמצעות ממשק דיגיטלי ייעודי מול הבנקים וגופי המימון הגדולים במדינה.
     הממשק משמש לשליחה וקבלת מידע באופן מדויק וקבלת היזון חוזר מגופי המימון תוך שעות ספורות ועד יום עסקים אחד. מימון כל פועלת באופן המהיר ביותר בתחום.`,
  ` לאחר שהתקבל המידע, נבנה תיק הלוואה כולל דו"ח אשראי מורחב מחברת נתוני האשראי "דן אנד ברדסטריט".
    בשלב הבא, היועצים והיועצות שלנו ישלחו פניה מקבילה לכל הבנקים וגופי המימון הגדולים במדינה בהתאם לצורך של כל לקוח ולקוחה במטרה לייצר תחרות ולמקסם את התנאים.`,
  `עם קבלת המענה מכל הגופים,
     נערוך השוואה בהתאם לצרכים של כל אחד ואחת, ונקדם את ההצעה הטובה ביותר מבחינת הריבית, תנאי ההחזר או כל פרמטר רלוונטי אחר, מול התאגיד הבנקאי או הפיננסי הרלוונטי.`,
];
