import { Link } from 'react-router-dom';

import { ReactComponent as SentMail } from '../../assets/icons/sentMail.svg';

import { BACK_HOME_PAGE, SENT_REQUEST_MESSAGE } from '../../constants';

const SentState = () => (
  <div className='absolute top-0 left-0 text-center w-full h-full flex flex-col flex-grow bg-white z-10'>
    <div className='mt-auto'>
      <SentMail className='mx-auto mb-5' />
      <p className='mx-auto w-2/3'>{SENT_REQUEST_MESSAGE}</p>
    </div>
    <Link className='mx-auto text-center bg-buttonBlue text-white rounded-3xl py-3 px-16 mt-auto mb-20 ' to='/main'>
      {BACK_HOME_PAGE}
    </Link>
  </div>
);

export default SentState;
