import Dropzone from 'react-dropzone';

import DropzoneFiles from './DropzoneFiles';

import { docsUploadStore } from '../../store';

import { ReactComponent as Upload } from '../../assets/icons/upload.svg';

import { ACCEPTED_FILES_TYPES, DROPZONE_NO_DOC_CLICK_ERROR, MAX_FILES, MAX_SIZE, UPLOAD } from '../../constants';

const UploadDropzone = () => {
  const {
    addFiles,
    displayError,
    files,
    setDisplayError,
    type,
  } = docsUploadStore(state => state);

  return (
    <>
      <button type='button' onClick={() => !type && setDisplayError(true)}>
        <Dropzone
          accept={ACCEPTED_FILES_TYPES}
          disabled={!type}
          maxFiles={MAX_FILES}
          maxSize={MAX_SIZE}
          onDrop={(acceptedFiles) => {
            addFiles(acceptedFiles.filter(file => !files.includes(file)));
          }}
        >
          {({ getInputProps, getRootProps }) => (
            <section
              {...getRootProps()}
              className='mt-8 text-center border border-dashed border-buttonBlue rounded-lg p-4 bg-[#4775ea0d]'
            >
              <input {...getInputProps()} />
              <Upload className='mx-auto my-5' />
              <h1 className='text-buttonBlue font-medium'>{UPLOAD.placeholder}</h1>
              <div className='text-lightGrey text-[10px] mt-6 mb-3'>
                <p>{UPLOAD.typeInstructions}</p>
                <p>{UPLOAD.sizeInstructions}</p>
              </div>
            </section>
          )}
        </Dropzone>
      </button>
      {displayError && <span className='block text-center mt-[8px] text-[12px] text-[#FE5C42] leading-5'>{DROPZONE_NO_DOC_CLICK_ERROR}</span>}
      {files.length > 0 && <DropzoneFiles />}
    </>
  );
};
export default UploadDropzone;
