import { FieldValues } from 'react-hook-form';

export const updateUserUtil = (userPutData: FieldValues) => {
  Object.keys(userPutData).forEach((putUserKey) => {
    if (userPutData[putUserKey] === 'false' || userPutData[putUserKey] === 'true') {
      Boolean(userPutData[putUserKey]);
      userPutData[putUserKey] = userPutData[putUserKey] === 'true';
    } else if (userPutData[putUserKey] === '') {
      userPutData[putUserKey] = undefined;
    } else if (putUserKey.includes('id') && !Number.isNaN(userPutData[putUserKey]) && !Number.isNaN(parseFloat(userPutData[putUserKey]))) {
      userPutData[putUserKey] = Number(userPutData[putUserKey]);
    }
  });
  return userPutData;
};
