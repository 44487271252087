import React, { useState } from 'react';

import Assistant from './Assistant';
import Details from './Details';

import { LoanRequest, LoanStatus } from '../../interfaces';

const RejectedDetails = ({ productName }: Omit<LoanRequest<LoanStatus.REJECTED>, 'status'>) => {
  const [pageDisplay, setPageDisplay] = useState<'details' | 'assistant'>('details');
  const RejectedLoanComponent = {
    details: <Details loanType={productName} setPageDisplay={setPageDisplay} />,
    assistant: <Assistant />,
  };

  const rejectedLoan = RejectedLoanComponent[pageDisplay] || RejectedLoanComponent.details;

  return (
    <div className='flex flex-col text-sm flex-grow mt-[39px] mx-[24px]'>
      {rejectedLoan}
    </div>
  );
};

export default RejectedDetails;
