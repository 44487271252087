import { Navigate, useParams } from 'react-router-dom';

import ConsultHeader from '../components/ConsultHeader';
import PageLayout from '../components/Layout/PageLayout';
import RejectedDetails from '../components/RejectedLoanRequestDetails/RejectedDetails';

import { LoanRequest, LoanStatus } from '../interfaces';
import { userStore } from '../store';

import { MAIN,
  REJECTED_REQUEST_HEADER } from '../constants';

const LoanRequestDetails = () => {
  const { index } = useParams();
  const { loanRequests } = userStore();

  if (loanRequests.length === 0) return <Navigate replace to={MAIN} />;

  const loanRequest = loanRequests[Number(index)] as LoanRequest<LoanStatus.REJECTED>;

  return (
    <PageLayout header={<ConsultHeader title={REJECTED_REQUEST_HEADER} />}>
      <RejectedDetails
        {...loanRequest}
      />
    </PageLayout>
  );
};

export default LoanRequestDetails;
