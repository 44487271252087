import { MENU_TEXT } from '../constants';

export const menuOptions = [
  {
    text: MENU_TEXT.main,
    navigationPath: '/main',
  },
  {
    text: MENU_TEXT.profile,
    navigationPath: '/personal-profile',
  },
  {
    text: MENU_TEXT.contact,
    navigationPath: '/contact-us',
  },
  {
    text: MENU_TEXT.faq,
    navigationPath: '/questions-and-answers',
  },
  {
    text: MENU_TEXT.infoCenter,
    navigationPath: '/info-center',
  },
  {
    text: MENU_TEXT.aboutUs,
    navigationPath: '/about-us',
  },
];
