import { userStore } from '../../store';

import assistant from '../../assets/images/assistant.png';

import { mainHeaderText } from '../../constants';

const Header = () => {
  const { hasBdi, user } = userStore();

  return (
    <div className='w-full flex mt-[25px] items-center'>
      <img
        alt='assistant'
        className='rounded-full border-[2px] border-white w-[80px] h-[80px] ml-[24px]'
        src={assistant}
      />
      <div>
        <p className='text-white font-bold'>
          {`${mainHeaderText.hiGreeting} ${(user && user.firstname) || ''},`}
        </p>
        <p className='text-white max-w-48'>{hasBdi ? mainHeaderText.subtitle : mainHeaderText.noBdiSubtitles}</p>
      </div>
    </div>
  );
};

export default Header;
