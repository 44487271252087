import { create } from 'zustand';

import { Doc } from '../interfaces';

import { TABS } from '../constants';

type Tabs = typeof TABS[keyof typeof TABS]
interface IPersonalProfileStore{
  tab: Tabs
  setTab: (tab: Tabs) => void
  docs: Doc[]
  setDocs: (docs: Doc[]) => void
  hasProfileChanged: boolean
  setHasProfileChanged: (hasProfileChanged: boolean) => void
}

const defaultStoreDate = {
  tab: TABS.DETAILS,
  docs: [],
  hasProfileChanged: false,
};

export const personalProfileStore = create<IPersonalProfileStore>((set, get) => ({
  ...defaultStoreDate,
  setTab: (tab) => set((state) => ({ ...state, tab })),
  setDocs: (docs) => set((state) => ({ ...state, docs })),
  setHasProfileChanged: (hasProfileChanged) => set((state) => ({ ...state, hasProfileChanged })),
}));
