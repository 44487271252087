export const UPLOAD_PAGE_TITLE = 'העלאת מסמך';

export const UPLOAD_SELECT_PLACEHOLDER = 'סוג מסמך';

export const UPLOAD = {
  placeholder: 'אנא העלה את המסמך לכאן',
  typeInstructions: 'תומך בקבצים מסוג : PDF, JPG',
  sizeInstructions: 'מקסימום גודל 20MB',
  submit: 'אישור',
};

export const TOAST_MESSAGE = {
  success: 'הקובץ עלה בהצלחה',
  error: 'הקובץ לא עלה בהצלחה',
  fileError: 'קובץ לא תקין, נסה קובץ אחר',
};

export const DROPZONE_NO_DOC_CLICK_ERROR = 'יש לבחור מסמך לפני העלאת הקבצים';
