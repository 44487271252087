import React, { useState } from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';

import Select from '../Form/Select/Select';

import { FormInputs, PersonalDetailInput } from '../../interfaces';

interface IFormSelectProps {
  item: PersonalDetailInput<FormInputs.select>
  field: ControllerRenderProps<FieldValues, string>
  serverOptions?: {key: string, value: string}[]
}

const FormSelect = ({ field, item, serverOptions }: IFormSelectProps) => {
  const [options, setOptions] = useState(() => {
    if (serverOptions) return serverOptions;
    if (item.options) return item.options;
    return [];
  });

  return (
    <Select
      {...item}
      {...field}
      value={field.value.toString()}
    >
      <option disabled hidden value='' />
      {options.map(({
        key,
        value,
      }) => (
        <option key={`option-${key}`} value={value}>{key}</option>
      ))}
    </Select>
  );
};

export default FormSelect;
