import { formatDate } from './formatDate';
import { isValidIsraeliID } from './israelIdValidation';

import { FormInputs, PersonalDetailInput } from '../interfaces';

import { emailRegex, ERRORS_MESSAGES, HebrewPattern, MAX_BIRTHDAY_YEAR_SUBTRACTION, MIN_BIRTHDAY_YEAR_SUBTRACTION, phoneRegex, SECTION } from '../constants';

const personalDetailsList = (): PersonalDetailInput<FormInputs>[] => {
  const {
    BIRTH_DATE, CITY, FIRST_NAME, LAST_NAME,
  } = SECTION.PERSONAL_DETAILS.FORM_LABELS;

  const today = new Date();

  return [
    {
      id: 'firstname',
      mode: FormInputs.input,
      placeholder: FIRST_NAME,
      maxLength: 15,
      minLength: 2,
      required: true,
      rules: {
        pattern: {
          value: HebrewPattern,
          message: ERRORS_MESSAGES.hebrewLetters,
        },
        minLength: {
          value: 2,
          message: ERRORS_MESSAGES.textShort,
        },
        maxLength: {
          value: 15,
          message: ERRORS_MESSAGES.textLong,
        },
        required: {
          value: true,
          message: ERRORS_MESSAGES.required,
        },
      },
    },
    {
      id: 'lastname',
      mode: FormInputs.input,
      type: 'text',
      required: true,
      placeholder: LAST_NAME,
      maxLength: 15,
      rules: {
        pattern: {
          value: HebrewPattern,
          message: ERRORS_MESSAGES.hebrewLetters,
        },
        minLength: {
          value: 2,
          message: ERRORS_MESSAGES.textShort,
        },
        maxLength: {
          value: 15,
          message: ERRORS_MESSAGES.textLong,
        },
        required: {
          value: true,
          message: ERRORS_MESSAGES.required,
        },
      },
    },
    {
      id: 'birthDate',
      mode: FormInputs.date,
      type: 'date',
      placeholder: BIRTH_DATE,
      min: formatDate(new Date(new Date().setFullYear(today.getFullYear() - MIN_BIRTHDAY_YEAR_SUBTRACTION))),
      max: formatDate(new Date(new Date().setFullYear(today.getFullYear() - MAX_BIRTHDAY_YEAR_SUBTRACTION))),
    },
    {
      id: 'city',
      mode: FormInputs.input,
      placeholder: CITY,
      rules: {
        pattern: {
          value: HebrewPattern,
          message: ERRORS_MESSAGES.hebrewLetters,
        },
      },
    },
  ];
};

const familySituationList = ():PersonalDetailInput<FormInputs>[] => {
  const {
    PARTNER_ID_NUMBER, STATUS,
  } = SECTION.FAMILY_SITUATION.FORM_LABELS;

  return [
    {
      id: 'status',
      mode: FormInputs.select,
      placeholder: STATUS,
      options: [
        {
          key: 'רווק/ה',
          value: '1',
        },
        {
          key: 'נשוי/אה',
          value: '2',
        },
        {
          key: 'גרוש/ה',
          value: '3',
        },
        {
          key: 'אלמן/ה',
          value: '4',
        },
        {
          key: 'אחר',
          value: '5',
        },
        {
          key: 'ידוע/ה בציבור',
          value: '6',
        },
      ],
    },
    {
      id: 'partnerID',
      mode: FormInputs.input,
      type: 'text',
      rules: {
        validate: (value) => (!value || isValidIsraeliID(value) ? true : ERRORS_MESSAGES.taz),
      },
      placeholder: PARTNER_ID_NUMBER,
    },
  ];
};

const communicationList = ():PersonalDetailInput<FormInputs>[] => {
  const {
    EMAIL, PHONE_NUM, SECONDARY_PHONE_NUM,
  } = SECTION.COMMUNICATION.FORM_LABELS;

  const phoneRule = {
    pattern: {
      value: phoneRegex,
      message: ERRORS_MESSAGES.phone,
    },
  };

  return [
    {
      id: 'email',
      mode: FormInputs.input,
      type: 'email',
      placeholder: EMAIL,
      rules: {
        pattern: {
          value: emailRegex,
          message: ERRORS_MESSAGES.email,
        },
      },
    },
    {
      id: 'phoneNum',
      mode: FormInputs.input,
      disabled: true,
      placeholder: PHONE_NUM,
      type: 'string',
      rules: {
        ...phoneRule,
      },
    },
    {
      id: 'secondaryPhoneNum',
      mode: FormInputs.input,
      placeholder: SECONDARY_PHONE_NUM,
      type: 'string',
      rules: {
        ...phoneRule,
      },
    },
  ];
};

const employmentList = ():PersonalDetailInput<FormInputs>[] => {
  const {
    EMPLOYMENT_STATUS,
    NET_INCOME_WITH_PARTNER, NET_MONTHLY_INCOME, PAYCHECK_DAY, SENIORITY,
  } = SECTION.EMPLOYMENT.FORM_LABELS;

  return [
    {
      id: 'employmentStatus',
      mode: FormInputs.select,
      placeholder: EMPLOYMENT_STATUS,
      options: [
        {
          key: 'שכיר',
          value: '1',
        },
        {
          key: 'עצמאי',
          value: '2',
        },
        {
          key: 'ללא',
          value: '6',
        },
        {
          key: 'פנסיה',
          value: '7',
        },
        {
          key: 'מקבל קיצבה',
          value: '8',
        },
        {
          key: 'אחר',
          value: '9',
        },
      ],
    },
    {
      id: 'seniority',
      mode: FormInputs.input,
      placeholder: SENIORITY,
      type: 'number',
      min: 0,
      rules: {
        minLength: {
          value: 1,
          message: ERRORS_MESSAGES.textShort,
        },
        min: {
          value: 0,
          message: ERRORS_MESSAGES.valueShort,
        },
      },
    },
    {
      id: 'paycheckDay',
      mode: FormInputs.input,
      type: 'number',
      placeholder: PAYCHECK_DAY,
      min: 0,
      max: 31,
      rules: {
        minLength: {
          value: 1,
          message: ERRORS_MESSAGES.textShort,
        },
        min: {
          value: 0,
          message: ERRORS_MESSAGES.valueShort,
        },
        max: {
          value: 31,
          message: ERRORS_MESSAGES.valueLong,
        },
      },
    },
    {
      id: 'netMonthlyIncome',
      mode: FormInputs.input,
      type: 'number',
      placeholder: NET_MONTHLY_INCOME,
      min: 1000,
      rules: {
        min: {
          value: 1000,
          message: ERRORS_MESSAGES.netMonthlyIncomeMin,
        },
      },
    },
    {
      id: 'netIncomeWithPartner',
      mode: FormInputs.input,
      type: 'number',
      placeholder: NET_INCOME_WITH_PARTNER,
    },
  ];
};

const additionalDetails = ():PersonalDetailInput<FormInputs>[] => {
  const {
    CAR_PRODUCTION_YEAR, HAS_CAR, HAS_CAR_LIEN, HAS_MORTGAGE,
    HAS_PROPERTY,
  } = SECTION.ADDITIONAL_DETAILS.FORM_LABELS;

  const options = [{ key: 'כן', value: 'true' }, { key: 'לא', value: 'false' }];

  return [
    {
      id: 'hasProperty',
      mode: FormInputs.select,
      placeholder: HAS_PROPERTY,
      options,
    },
    {
      id: 'hasMortgage',
      placeholder: HAS_MORTGAGE,
      mode: FormInputs.select,
      options,
    },
    {
      id: 'hasCar',
      placeholder: HAS_CAR,
      mode: FormInputs.select,
      options,
    },
    {
      id: 'carProductionYear',
      placeholder: CAR_PRODUCTION_YEAR,
      mode: FormInputs.input,
      required: true,
      min: 2000,
      max: new Date().getFullYear(),
      rules: {
        required: {
          value: true,
          message: ERRORS_MESSAGES.required,
        },
        min: {
          value: 2000,
          message: ERRORS_MESSAGES.carProductionYearMin,
        },
        max: {
          value: new Date().getFullYear(),
          message: ERRORS_MESSAGES.carProductionYearMax,
        },

      },
      type: 'number',
    },
    {
      id: 'hasCarLien',
      placeholder: HAS_CAR_LIEN,
      mode: FormInputs.select,
      options: [{ key: '', value: '' }, ...options],
    },
  ];
};

export default [personalDetailsList, familySituationList, communicationList, employmentList, additionalDetails];
