import loadingCubes from '../../assets/icons/loadingCubes.gif';

import { LOADING } from '../../constants';

const Loading = () => (
  <div className='fixed flex flex-col w-full h-full justify-center items-center z-20 bg-gradient-to-t from-[#addcfff5] via-[#eaf6fff5] to-[#eaf6fff5]'>
    <img alt='loading-cubes' className='h-[133px] w-[133px]' src={loadingCubes} />
    <span className='text-lg font-semibold'>{LOADING}</span>
  </div>
);

export default Loading;
