import AboutUs from '../pages/AboutUs';
import Consult from '../pages/Consult';
import ContactUs from '../pages/ContactUs';
import CreditRatingInfoPage from '../pages/CreditRatingInfoPage';
import FaqPage from '../pages/FaqPage';
import InfoCenter from '../pages/InfoCenter';
import LoanRequestDetails from '../pages/LoanRequestDetails';
import LoansTypes from '../pages/LoansTypes';
import MainPage from '../pages/MainPage';
import PersonalProfile from '../pages/PersonalProfile';
import PreviousLoan from '../pages/PreviousLoan';
import PreviousLoans from '../pages/PreviousLoans';
import UploadPage from '../pages/UploadPage';

export const routes = [
  {
    path: 'main',
    element: <MainPage />,
  },
  {
    path: 'credit-rating',
    element: <CreditRatingInfoPage />,
  },
  {
    path: 'consult',
    element: <Consult />,
  },
  {
    path: 'personal-profile',
    element: <PersonalProfile />,
  },
  {
    path: 'loans-types',
    element: <LoansTypes />,
  },
  {
    path: 'contact-us',
    element: <ContactUs />,
  },
  {
    path: 'prev-loans',
    element: <PreviousLoans />,
    children: [
      {
        path: ':id',
        element: <PreviousLoan />,
      },
    ],
  },
  {
    path: 'questions-and-answers',
    element: <FaqPage />,
  },
  {
    path: 'info-center',
    element: <InfoCenter />,
  },
  {
    path: 'upload',
    element: <UploadPage />,
  },
  {
    path: 'about-us',
    element: <AboutUs />,
  },
  {
    path: 'loan-request/:index',
    element: <LoanRequestDetails />,
  },
];
