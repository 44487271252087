import { ReactComponent as PhoneSvg } from '../../assets/icons/phone.svg';
import { ReactComponent as WhatsappSvg } from '../../assets/icons/whatsappLogo.svg';

import { CUSTOMER_SERVICE } from '../../constants';

const Phone = () => (

  <div className='flex flex-col font-bold text-buttonBlue text-small py-7 border-b gap-4'>
    <a
      aria-label='Phone Call'
      className='flex gap-2 items-center'
      href={`https://api.whatsapp.com/send?phone=${CUSTOMER_SERVICE.phone.phone_mobile_number}`}
      rel='noreferrer'
      target='_blank'
    >
      <WhatsappSvg className='h-[24px]' />
      <p>{CUSTOMER_SERVICE.phone.whatsapp}</p>
    </a>
    <a
      aria-label='Phone Call'
      className='flex gap-2 items-center direction-ltr justify-end'
      href={`tel:${CUSTOMER_SERVICE.phone.phone_number}`}
      rel='noreferrer'
      target='_blank'
    >
      {CUSTOMER_SERVICE.phone.phone_number}
      <PhoneSvg className='h-[24px]' />
    </a>
  </div>
);
export default Phone;
