import { FunctionComponent, SVGProps } from 'react';

interface IInfoSection {
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>
    description: string
}

const InfoSection = ({ description, Icon }: IInfoSection) => (
  <div>
    <div className='relative top-6 bg-[#e3f5fb] rounded-full border-[1.5px] border-buttonBlue mx-auto w-fit p-2.5'>
      <Icon />
    </div>
    <p className='bg-[#B9E7F466] rounded-[10px] pt-8 pb-5 px-4'>{description}</p>
  </div>
);

export default InfoSection;
