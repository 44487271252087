export function convertBase64ToFile(base64String: string, fileName: string) {
  try {
    const byteCharacters = atob(base64String);

    const byteNumbers = byteCharacters.split('').map(((_byteCharacter, index) => byteCharacters.charCodeAt(index)));

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });

    return new File([blob], fileName, { type: blob.type });
  } catch (error) {
    console.error('Error converting Base64 to file:', error);

    return undefined;
  }
}
