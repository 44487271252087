import bailLoan from '../../assets/images/bailLoan.png';
import handHoldingPen from '../../assets/images/carLoan.png';
import emptyBox from '../../assets/images/emptyBox.png';
import forAnyPurposeLoan from '../../assets/images/forAnyPurposeLoan.png';
import loanConsolidation from '../../assets/images/loanConsolidation.png';
import propertyLoan from '../../assets/images/propertyLoan.png';

export const LOANS_TYPES_TITLE = 'הצעות לסוגי הלוואות שונות עבורך';

export const LOANS_TYPES_SUB_TITLE = ' על פי דירוג האשראי שלך';

export const APPLY_FOR_LOAN = 'הגש בקשה להלוואה';

export const NO_TYPES = {
  icon: emptyBox,
  content: ' מצטערים אבל לא מצאנו הצעות שיכולות להתאים לך כרגע. שיפור בדירוג האשראי יעזור לך לקבל הצעות.',
  buttonText: 'להמלצות לדירוג האשראי',
};

export const TYPE_DESCRIPTION = `אם אתה מעוניין לקבל את ההלוואה. לחץ על ״${APPLY_FOR_LOAN}״`;
export const LOANS_TYPES_ICONS = {
  'הלוואה כנגד שיעבוד רכב': handHoldingPen,
  'הלוואה אל מול נכס קיים': propertyLoan,
  'הלוואה בערבות המדינה': bailLoan,
  'הלוואה חוץ בנקאית לכל מטרה': forAnyPurposeLoan,
  'איחוד הלוואות': loanConsolidation,
};
