import { JSX, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import Loading from '../components/Loading/Loading';

import useApiRequests from '../hooks/useApiRequests';
import useFetch from '../hooks/useFetch';
import useIsMobile from '../hooks/useIsMobile';
import { User } from '../interfaces';
import { userStore } from '../store';

import { LOAN_REQUEST_FETCH_TIME, NON_MOBILE } from '../constants';

interface ProtectedRouteProps {
  redirectPath: string;
  children: JSX.Element;
}
const ProtectedRoute = ({ children, redirectPath } : ProtectedRouteProps) => {
  const navigate = useNavigate();
  const { getLoanRequests, refreshToken } = useApiRequests();
  const { setLoanRequests } = userStore();
  const { error, loading, request } = useFetch();
  const isMobile = useIsMobile();

  const {
    blockNavigation, setBlockNavigation, setUser, user,
  } = userStore();

  const fetchProfile = async () => {
    if (!user && isMobile) {
      if (blockNavigation) return navigate(redirectPath);

      if (!sessionStorage.getItem('accessToken')) {
        const data = await refreshToken();
        if (!data?.accessToken) {
          return navigate(redirectPath);
        }
        sessionStorage.setItem('accessToken', data.accessToken);
      }
      return request<User>('/user')
        .then(user => {
          if (user) {
            return setUser(user);
          }
          setBlockNavigation(true);
          return navigate(redirectPath);
        });
    }
  };

  const initiateProfile = () => {
    if ((window as any).Android) {
      try {
        const androidUser = (window as any).Android?.getUser();
        const androidToken = (window as any).Android?.getToken();

        if (!androidUser || !androidToken) (window as any).Android?.logout();
        sessionStorage.setItem('accessToken', androidToken);
        setUser(JSON.parse(androidUser));
      } catch (e) {
        console.log(e);
        if (!sessionStorage.getItem('accessToken') || !user) (window as any).Android?.logout();
      }
    }

    fetchProfile();
  };

  const fetchLoanRequests = async () => {
    const loanRequests = await getLoanRequests();
    setLoanRequests(loanRequests ?? []);
  };

  useEffect(() => {
    initiateProfile();

    const loanRequestInterval = setInterval(fetchLoanRequests, LOAN_REQUEST_FETCH_TIME);
    return () => {
      clearInterval(loanRequestInterval);
    };
  }, []);

  if (!isMobile) return <Navigate replace to={NON_MOBILE} />;

  if (user && !loading) return children;

  if (error) return <Navigate replace to={redirectPath} />;

  return <Loading />;
};

export default ProtectedRoute;
