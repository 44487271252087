import { ButtonHTMLAttributes } from 'react';

interface ISlideShow extends ButtonHTMLAttributes<HTMLButtonElement>{
  activeIndex: number
  index: number
}
const SlideDot = ({ activeIndex, index, ...buttonProps }: ISlideShow) => (
  <button
    className={`rounded-full mb-2.5 w-[8px] h-[8px] mx-[4px] bg-buttonBlue ${activeIndex <= index ? '' : 'opacity-20'} ${activeIndex === index ? 'px-2' : ''}`}
    type='button'
    {...buttonProps}
  />
);

export default SlideDot;
