import { FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import OTPInput from './OTPInput';
import PhoneInput from './PhoneInput';
import PrivacyPolicyPopup from './PrivacyPolicyPopup';
import Start from './Start';

import useApiRequests from '../../hooks/useApiRequests';
import { userStore } from '../../store';
import { loginStore } from '../../store/loginStore';
import { mimoonPopupOptions } from '../../utils/popup';

import {
  loginPageText, MAIN, PRIVACY_POLICY,
} from '../../constants';

const LoginForm = () => {
  const navigate = useNavigate();
  const {
    error, loading, verifyOTPRequest, verifyPhoneRequest,
  } = useApiRequests();

  const { setBlockNavigation, setUser } = userStore();
  const {
    checkedPrivacyPolicy,
    formData,
    inputValidated,
    loginStep,
    phoneVerified,
    reset,
    setInputValidated,
    setShowLetsStart,
    showLetsStart,
    togglePrivacyPolicy,
  } = loginStore();

  const handleVerifyPhone = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!(!inputValidated || !checkedPrivacyPolicy)) {
      const response = await verifyPhoneRequest(formData.phoneNumber);

      if (response && response.status === 'pending') {
        phoneVerified();
      }

      if (response && response.message === 'refreshToken is valid') {
        setBlockNavigation(false);
        reset();
        navigate(MAIN);
      }
    }
  };

  const handleVerifyOTP = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    const response = await verifyOTPRequest(formData.phoneNumber, formData.OTPCode);
    if (response && response.validate === true) {
      setUser(response.user);
      sessionStorage.setItem('accessToken', response.accessToken);
      if (!response.user.useMobileApplication) {
        return navigate('/walkthrough');
      }

      return setShowLetsStart(true);
    }
    if (response && response.message === 'refreshToken is valid') {
      setBlockNavigation(false);
      reset();
      navigate(MAIN);
    }
    return setInputValidated(false);
  };

  const handlePrivacyPolicy = async () => {
    await withReactContent(Swal).fire(mimoonPopupOptions({
      html: <PrivacyPolicyPopup />,
    }));
  };

  const LoginComponents = {
    phone: <PhoneInput error={error} loading={loading} />,
    otp: <OTPInput loading={loading} resendOtp={verifyPhoneRequest} />,
  };
  const loginContent = LoginComponents[loginStep] || LoginComponents.phone;

  return (
    !showLetsStart
      ? (
        <form className='flex flex-col h-full justify-between' onSubmit={loginStep === 'phone' ? handleVerifyPhone : handleVerifyOTP}>
          {loginContent}
          <div className='flex flex-col mx-[28px]'>
            {loginStep === 'phone' && (
            <div className='flex p-2 py-4'>
              <input
                className='scale-125'
                defaultChecked={checkedPrivacyPolicy}
                dir='rtl'
                id='privacy-policy'
                type='checkbox'
                value={formData.phoneNumber}
                onChange={togglePrivacyPolicy}
              />
              <label className='flex items-center cursor-pointer' htmlFor='privacy-policy'>
                <span className='mr-2'>
                  {PRIVACY_POLICY.content}
                  <button className='text-buttonBlue underline' type='button' onClick={handlePrivacyPolicy}>{PRIVACY_POLICY.link}</button>
                </span>
              </label>
            </div>
            )}

            <button
              className='py-[14px] mb-[64px] rounded-[29px] bg-buttonBlue text-white font-bold flex-end disabled:bg-opacity-50'
              disabled={!inputValidated || !checkedPrivacyPolicy}
              type='submit'
            >
              {loginPageText[loginStep].next_button}
            </button>
          </div>
        </form>
      )
      : (
        <Start />
      )
  );
};

export default LoginForm;
