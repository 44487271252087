import { Link } from 'react-router-dom';

import { GenericLeftArrow } from '../../../assets/genericLeftArrow';

import { ADDITIONAL_INFO, COLORS, INFO_CENTER } from '../../../constants';

export interface IMainCarouselCardProps {
  title: string,
  content: string[]
  button?: {
    content: string,
    navLink: string
  }
}

const MainCarouselCard = (props: IMainCarouselCardProps) => {
  const { button, content, title } = props;
  return (
    <div>
      <div className='pl-[11px] h-full'>
        <div className='w-full h-full gap-y-2 rounded-[21px] px-[17px] py-[18px] flex flex-col bg-white direction-rtl'>
          <div className='font-bold text-small'>
            {title}
          </div>
          <div className='flex flex-col gap-y-3 text-greyed text-[12px]'>
            {content.map((data, i) => (
              <p key={`${i}-${data}`}>
                {data}
              </p>
            ))}
          </div>
          <Link className='flex text-buttonBlue gap-x-1.5 text-[12px] mt-auto items-center font-bold justify-end' to={button?.navLink ?? INFO_CENTER}>
            {button?.content ?? ADDITIONAL_INFO}
            <GenericLeftArrow strokeColor={COLORS.DEEP_SKY_BLUE} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MainCarouselCard;
