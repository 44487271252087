import {
  useEffect,
} from 'react';
import {
  Controller, FieldValues, SubmitHandler, useForm,
} from 'react-hook-form';

import DatePicker from '../Form/Input/DatePicker';
import Input from '../Form/Input/Input';
import FormSelect from './FormSelect';

import { FormInputs, PersonalDetailInput } from '../../interfaces';
import { personalProfileStore, userStore } from '../../store';
import { formObjectDiff } from '../../utils/objectDiff';

import { DEPENDANT_ITEM, DEPENDANT_LIST, UPDATE_BUTTON_TEXT } from '../../constants';

type Props = {
  onSubmit: SubmitHandler<FieldValues>
  inputList: PersonalDetailInput<FormInputs>[]
}

const SectionForm = ({ inputList, onSubmit }: Props) => {
  const { hasProfileChanged, setHasProfileChanged } = personalProfileStore();
  const { user: userProfile } = userStore();

  const {
    clearErrors,
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const watchedItem = watch(DEPENDANT_ITEM, userProfile![DEPENDANT_ITEM] ?? '');

  useEffect(() => {
    const subscription = watch((data) => {
      if (DEPENDANT_ITEM in data && JSON.parse(data[DEPENDANT_ITEM]) !== userProfile![DEPENDANT_ITEM] && JSON.parse(data[DEPENDANT_ITEM])) {
        const hasValues = DEPENDANT_LIST.every(dependant => data[dependant]);
        setHasProfileChanged(hasValues && formObjectDiff(data, { ...userProfile }));
      } else {
        setHasProfileChanged(formObjectDiff(data, { ...userProfile }));
      }
    });
    return () => subscription.unsubscribe();
  }, [userProfile]);

  return (
    <form
      className='grid gap-y-10 pt-8'
      onSubmit={handleSubmit(onSubmit)}
    >
      {
          inputList.map(item => {
            if (!(DEPENDANT_LIST.includes(item.id) && !JSON.parse(watchedItem))) {
              return (
                <Controller
                  key={item.id}
                  control={control}
                  defaultValue={userProfile ? userProfile[item.id] ?? '' : ''}
                  name={item.id}
                  render={({ field, fieldState }) => {
                    if (item.mode === FormInputs.select) {
                      return (
                        <FormSelect
                          field={field}
                          item={item}
                        />
                      );
                    }

                    if (item.type === 'date') {
                      return (
                        <DatePicker
                          {...item}
                          {...field}
                          autoComplete='off'
                          onChange={(e) => {
                            field.onChange(e.target.valueAsDate);
                          }}
                        />
                      );
                    }
                    return (
                      <Input
                        {...item}
                        {...field}
                        autoComplete='off'
                        errorMessage={fieldState.error?.message}
                        onChange={(e) => {
                          switch (item.type) {
                            case 'number':
                              field.onChange(e.target.valueAsNumber || '');
                              break;
                            default:
                              field.onChange(e);
                          }

                          if (e.target.value === '') clearErrors(item.id);
                        }}
                      />
                    );
                  }}
                  rules={item.mode === FormInputs.input ? item.rules : undefined}
                />
              );
            }
            return null;
          })
      }
      {hasProfileChanged ? (
        <div className='fixed z-10 px-10 py-5 w-full right-0 bottom-0 bg-white shadow-blur'>
          <button
            className='w-full transition py-4 rounded-3xl bg-buttonBlue text-white font-bold disabled:opacity-60'
            disabled={Object.keys(errors).length !== 0}
            type='submit'
          >
            {UPDATE_BUTTON_TEXT}
          </button>
        </div>
      ) : null}

    </form>
  );
};

export default SectionForm;
