import { IMainCarouselCardProps } from '../components/Main/Carousel/MainCarouselCard';

import { Insight, Recommendations } from '../interfaces';

import { CONSULTANT, CONTINUE_CONSULTING } from '../constants';

export const insightToCarousel = (insight: Insight): IMainCarouselCardProps => ({
  title: insight.insight,
  content: [
    insight.recommendation,
    insight.action,
  ],
  button: {
    content: CONTINUE_CONSULTING,
    navLink: CONSULTANT,
  },
});

export const recommendationToCarousel = (recommendation: Recommendations): IMainCarouselCardProps => ({
  title: recommendation.title ?? 'המלצה',
  content: [
    recommendation.recomandationSummery,
    recommendation.recomendation ?? '',
  ],
});
