import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import Layout from '../components/Layout/Layout';
import LoginPage from '../pages/LoginPage';
import NonMobileError from '../pages/NonMobileError';
import NotRegisteredPage from '../pages/NotRegisteredPage';
import WalkthroughPage from '../pages/WalkthroughPage';
import ProtectedRoute from './ProtectedRoutes';

import { routes } from './routes';

export const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
    errorElement: <div>Error</div>,
  },
  {
    path: '/create-account',
    element: <NotRegisteredPage />,
    errorElement: <div>Error</div>,
  },
  {
    path: '/non-mobile',
    element: <NonMobileError />,
  },
  {
    path: '/walkthrough',
    element: <ProtectedRoute redirectPath='/login'><WalkthroughPage /></ProtectedRoute>,
  },
  ...routes.map(route => ({
    path: route.path,
    element: <ProtectedRoute redirectPath='/login'><Layout>{route.children ? <Outlet /> : route.element}</Layout></ProtectedRoute>,
    errorElement: <div>Error</div>,
    children: route.children ? [
      {
        path: '',
        element: route.element,
      },
      ...route.children.map(child => ({
        path: child.path,
        element: child.element,
      })),
    ] : [],
  })),
  {
    path: '*',
    element: <ProtectedRoute redirectPath='/login'><Navigate to='/main' /></ProtectedRoute>,
  },
]);
