import { Link } from 'react-router-dom';

import { GenericLeftArrow } from '../../../assets/genericLeftArrow';
import extractTextFromHTML from '../../../utils/extractTextFromHTML';

import { ADDITIONAL_INFO, COLORS } from '../../../constants';

import '../../../styles/custom.css';

export interface ICarouselCardProps {
  subject: string
  description: string
}
const NoBdiCard = (props: ICarouselCardProps) => {
  const {
    description, subject,
  } = props;
  return (
    <div className='pl-[11px]'>
      <div className='w-full rounded-[21px] px-[17px] py-[18px] flex flex-col bg-white direction-rtl'>
        <div className='font-bold text-small'>
          {subject}
        </div>
        <div className='text-greyed text-[12px] clamp-lines overflow-hidden mt-1'>
          {extractTextFromHTML(description)}
        </div>
        <Link className='flex text-buttonBlue gap-x-1.5 text-[12px] mt-[15px] items-center font-bold justify-end' to='/info-center'>
          {ADDITIONAL_INFO}
          <GenericLeftArrow strokeColor={COLORS.DEEP_SKY_BLUE} />
        </Link>
      </div>
    </div>
  );
};

export default NoBdiCard;
