import { Loan, LoanKeys } from '../../interfaces';
import { getValue } from '../../utils/previousLoansUtils';

import { LOAN_OFFER_LIST_CARD_DETAILS as details } from '../../constants';

interface ICarDetails {
    loanOfferCard: Loan
}
const CardDetails = ({ loanOfferCard }: ICarDetails) => (
  <div>
    {details.map(detail => {
      const value = loanOfferCard[detail.keyValue as LoanKeys];
      return (
        <div key={detail.keyValue} className='flex justify-between mx-5 py-[10px] text-[12px] border-b border-b-borderGray'>
          <span>{detail.name}</span>
          <span className='font-semibold'>{getValue(detail, value)}</span>
        </div>
      );
    })}
  </div>
);

export default CardDetails;
