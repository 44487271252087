import { LoanLabels } from '../../interfaces';

export const LOANS_OFFERS_TITLE = 'ההלוואות שלך';
export const LOANS_OFFERS_SUB_TITLE = 'הלוואות קודמות שקיבלת לפי הנתונים שיש לי';

export const LOAN_CONSOLIDATION = {
  title: {
    beginning: 'מצאתי אפשרות',
    bold: 'לאיחוד הלוואות',
    ending: ' ולחסוך לך כסף!',
  },
  description: 'לאחר בדיקה שעשיתי, מצאתי שאתה יכול לקבל תנאים טובים יותר להלוואות הקיימות שלך, לחץ כאן לעוד פרטים',
  productId: 'd273b0f1-431b-48ff-bf3e-30fc1a0ef4d9',
};

export const NO_OFFERS = {
  title: 'לא קיימות הלוואות',
  content: 'מהנתונים שבידנו לא קיימות לך הלוואות קודמות, במידה ואתה מעוניין בהלוואה משתלמת במיוחד, אנחנו כאן בשבילך',
  buttonText: 'לקבלת הצעה משתלמת להלוואה',
};

export const LOAN_OFFER_LIST_CARD_DETAILS: LoanLabels[] = [
  {
    name: 'הגוף הממן',
    keyValue: 'fundingOrg',
  },
  {
    name: 'סכום הלוואה',
    keyValue: 'quantity',
    isMoney: true,
  },
  {
    name: 'תאריך תחילת העסקה',
    keyValue: 'startDate',
  },
  {
    name: 'תאריך סיום מתוכנן',
    keyValue: 'endDate',
  },
];
