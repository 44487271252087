import { Link } from 'react-router-dom';

import ConsultHeader from '../components/ConsultHeader';
import ContactUsVia from '../components/Generic/ContactUsVia';
import PageLayout from '../components/Layout/PageLayout';

import { BACK_HOME_PAGE, CONSULT_TEXT, MAIN } from '../constants';

const Consult = () => (
  <PageLayout header={<ConsultHeader title={CONSULT_TEXT.title} />}>
    <div className='flex flex-col h-full mx-auto gap-5 p-9 text-center'>
      <p>{CONSULT_TEXT.contactUsVia}</p>
      <ContactUsVia />
      <Link
        className='mt-auto w-full py-4 rounded-3xl bg-buttonBlue text-white font-bold flex-end'
        to={MAIN}
      >
        {BACK_HOME_PAGE}
      </Link>
    </div>
  </PageLayout>
);

export default Consult;
