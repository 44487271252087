import InfoSection from '../components/AboutUs/InfoSection';
import ValueSection from '../components/AboutUs/ValueSection';
import Collapse from '../components/Collapse/Collapse';
import PageLayout from '../components/Layout/PageLayout';

import { INFO_PROFILE, INFO_SECTIONS, INFO_TITLE, INFO_VALUES_TITLE, VALUES_SECTIONS } from '../constants';

const AboutUs = () => (
  <PageLayout header={INFO_TITLE}>
    <div className='w-10/12 mx-auto pb-4 text-sm'>
      <div className='mt-14'>
        <span className='font-semibold'>{INFO_PROFILE.title}</span>
        <p className='mt-6 font-normal'>{INFO_PROFILE.description}</p>
      </div>
      <div className='flex flex-col text-center my-4 gap-6'>
        {
            INFO_SECTIONS.map((section, index) => (
              <InfoSection key={`info-section-${index}`} {...section} />
            ))
          }
      </div>
      <div>
        <Collapse label={INFO_VALUES_TITLE} type='primary'>
          <div className='flex flex-col text-center mt-8'>
            {
                VALUES_SECTIONS.map((value: string, index) => (
                  <ValueSection key={`info-value-${index}`} description={value} />
                ))
            }
          </div>
        </Collapse>
      </div>
    </div>
  </PageLayout>
);

export default AboutUs;
