import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useBlockNavigation = (shouldBlock: boolean) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!shouldBlock) return () => {};

    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault();
      navigate(location.pathname, { replace: true });
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [shouldBlock, navigate, location]);
};

export default useBlockNavigation;
