import { MutableRefObject, useEffect, useRef } from 'react';

const UseClickOutside = <T extends HTMLElement>(handler: Function, boundaryRef?: MutableRefObject<T | null>) => {
  const wrapperRef = useRef<T | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current
          && !wrapperRef.current.contains(event.target as Node)
          && (!boundaryRef || (boundaryRef && boundaryRef.current && boundaryRef.current.contains(event.target as Node)))) {
        handler();
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handler]);

  return wrapperRef;
};

export default UseClickOutside;
